import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageRegionsList } from './RegionsList';
import { PageRegionsEdit } from './RegionsEdit';

export const PageRegions = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageRegionsList}
      PageAdd={PageRegionsEdit}
      PageEdit={PageRegionsEdit}
    />
  );
});