import React from 'react';
import { TRouteProps } from '../../utils/routerProvider';


export const PageListBaseA = React.memo((props: TRouteProps & {
  PageEdit?: React.ElementType<TRouteProps>,
  PageAdd?: React.ElementType<TRouteProps>,
  PageList: React.ElementType<TRouteProps>,
}) => {
  const {
    params,
    PageEdit,
    PageAdd,
  } = props;
  const {
    childPage,
  } = params;

  let childPageContent: any;
  if (PageEdit && childPage === 'edit') {
    childPageContent = (<PageEdit {...props}/>);
  }
  if (PageAdd && childPage === 'add') {
    childPageContent = (<PageAdd {...props}/>);
  }

  return (
    <>
      <props.PageList {...props}/>
      {
        childPageContent ? (
          <div className='abs s ovA bg2'>
            {childPageContent}
          </div>
        ) : null
      }
    </>
  );
});