import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TPushCustomModalAcceptParams, pushCustomModalAccept } from './pushCustomModalAccept';


export const pushCustomModalRemove = (params: TPushCustomModalAcceptParams) => {
  return pushCustomModalAccept({
    ...params,
    icon: <DeleteOutlineIcon className="sq40*2 dB*2"/>,
    title: params.title || 'Вы уверены, что хотите удалить выбранные элементы?',
  });
};
