import create from 'lodash/create';
import { pushArray } from './pushArray';


export const childClassOfReact = (
  Parent: any,
  constructor: (self: any, props?: Record<string, any>) => void,
  proto?: Record<string, any>,
) => {
  function Child() {
    const self = this, args: any[] = arguments as any; // eslint-disable-line
    Parent.apply(self, args);
    constructor.apply(self, pushArray([self], args) as any); // eslint-disable-line
  }
  Child.prototype = create(Parent.prototype, proto);
  return Child;
};