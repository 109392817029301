import noop from "lodash/noop";

export const attachEvent = (
  node: HTMLElement,
  eventName: string,
  callback: EventListenerOrEventListenerObject,
  options?: boolean | AddEventListenerOptions | undefined
) => {
  if (node.addEventListener) {
    node.addEventListener(eventName, callback, options || (options = false));
    return () => {
      node && ( // eslint-disable-line
        node.removeEventListener(eventName, callback, options),
        (node as any) = (eventName as any) = (callback as any) = (options as any) = null
      );
    };
  }
  if ((node as any).attachEvent) {
    (node as any).attachEvent(eventName = 'on' + eventName, callback);
    return () => {
      node && ( // eslint-disable-line
        (node as any).detachEvent(eventName, callback),
        (node as any) = (eventName as any) = (callback as any) = (options as any) = null
      );
    };
  }
  return noop;
};