
export function createTimeout(
  callback: (...args: any[]) => any,
  delay: number,
  args?: any[],
  ctx?: any
) {
  let intervalId = setTimeout(() => {
    callback && callback.apply(ctx || null, args || []);
  }, delay || 0);
  
  return () => {
    intervalId && clearTimeout(intervalId);
    (intervalId as any) = 0;
    (callback as any) = 0;
  };
}