import { createStore, createApi, Store } from 'effector';
import extend from 'lodash/extend';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import keys from 'lodash/keys';
import isFunction from 'lodash/isFunction';
import {
  isEqual,
} from './isEqual';
import {
  urlParse,
  TUrlProps,
} from './urlParse';
import {
  urlExtend,
} from './urlExtend';
import {
  withoutProps,
} from './withoutProps';
import {
  routeParseProvider,
} from './routeParseProvider';
import {
  isMatch,
} from './isMatch';
import {
  childClassOfReact,
} from './childClassOfReact';
import { TParams } from './unparam';
import { TRouteMapper } from './regexpMapperProvider';
import { valueWrapFn } from './valueWrapFn';


export type {
  TUrlProps,
  TParams,
  TRouteMapper,
};

export type TRouteProps = {
  params: TParams,
  location: TUrlProps,
} & Record<string, any>;
export type TRouteHandler<T> = (params: TParams, location: TUrlProps) => T | undefined | null;
export type TRouteArgs<T> = [string, TRouteHandler<T> | T];
export type TLocationOptions = {
  quiet?: boolean,
};

export type TLinkProps = {
  href?: string,
  options?: Partial<TUrlProps>,
  onClick?: React.MouseEventHandler<HTMLAnchorElement>,
  timeout?: number,
  active?: boolean,
  component?: React.ElementType,
};

export type TPushLocation = (extendsLocation: Partial<TUrlProps>, options?: TLocationOptions) => TUrlProps;


const WITHOUT_FIELDS_LINK = [
  'onClick', 'options', 'component',
  'timeout', 'href', 'activeAsParent',
  'active',
];
const NOOP_OBJECT: TParams = {};

export function mergeLocation(prev: Partial<TUrlProps>, exten: Partial<TUrlProps>) {
  const child: Partial<TUrlProps> = exten.child || {};
  const prevChild: Partial<TUrlProps> = prev.child || {};
  return urlExtend(exten.path || prev.path, {
    query: exten.query || null,
    child: urlExtend(child.path || prevChild.path, {
      query: child.query || null,
    }),
  });
}
function getPath(v: Partial<TUrlProps>) {
  return v.path || '';
}
function getChild(v: Partial<TUrlProps>): TUrlProps {
  return urlExtend(v.child);
}
function getQuery(v: Partial<TUrlProps>): TParams {
  return v.query || NOOP_OBJECT;
}
export function routerForObservableMapProvider<T>(
  _routes: TRouteArgs<T>[],
  notFoundHandler?: TRouteHandler<T>,
) {
  const routes: [TRouteMapper, TRouteHandler<T>][] = map(_routes, ([route, render]) => {
    return [routeParseProvider(route), isFunction(render) ? render : valueWrapFn(render)];
  });
  return (location: TUrlProps): T & TRouteProps => {
    const path = location.path || '';
    const length = routes.length;
    let i = 0, params: TParams = {}, result: any, route: [TRouteMapper, TRouteHandler<T>];
    for (; i < length; i++) {
      route = routes[i];
      if (
        route[0](path, params = {})
        && (result = route[1](params, location))
      ) {
        break;
      }
    }

    if (!result && notFoundHandler) {
      result = notFoundHandler(params, location);
    }

    return {
      params,
      location,
      ...(result || {})
    };
  };
}
export function routerByLocationProviderProvider($location: Store<TUrlProps>) {
  return function routerByLocationProvider<T>(
    routes: TRouteArgs<T>[],
    notFoundHandler?: TRouteHandler<T>,
  ) {
    return $location.map(routerForObservableMapProvider(routes, notFoundHandler));
  };
}
export function routerProvider({
  Component,
  window,
  createElement,
  forwardRef,
}: {
  Component: React.ElementType,
  window: Window,
  createElement: (...args: any[]) => any,
  forwardRef: (...args: any[]) => any,
}) {
  let hasDurationLink = 0;
  let _globalLocation: TUrlProps;
  let _locked = 0;
  const {
    location,
    history,
  } = window;
  const $location = createStore(_globalLocation = parseLocation());
  const $query = $location.map(getQuery);
  
  const {
    emitLocation,
  } = createApi($location, {
    emitLocation: (_, payload: TUrlProps) => payload,
  });
  const $path = $location.map(getPath);
  const $hashLocation = $location.map(getChild);
  const $hashQuery = $hashLocation.map(getQuery);
  const $hashPath = $hashLocation.map(getPath);
  const Link = LinkProvider({
    pushLocation,
  });
  const HashLink = LinkProvider({
    pushLocation: pushHashLocation,
    hasHash: true,
  });
  const NavLink = NavLinkProvider(Link, $location);
  const HashNavLink = NavLinkProvider(HashLink, $hashLocation);

  window.addEventListener('popstate', () => {
    emitLocation(_globalLocation = parseLocation());
  });

  function parseLocation() {
    return urlParse(location.href);
  }
  function changeLocation(
    location: TUrlProps,
    options?: TLocationOptions,
    replace?: boolean | number,
  ) {
    (replace ? history.replaceState : history.pushState).call(
        history, null, '', location.href,
    );
    _globalLocation = location;
    options && options.quiet || emitLocation(location); // eslint-disable-line
    return location;
  }
  function pushLocation(
    extendsLocation: Partial<TUrlProps>,
    options?: TLocationOptions,
  ) {
    return changeLocation(
        mergeLocation(_globalLocation, extendsLocation), options,
    );
  }
  function storagePushLocation(
    extendsLocation: Partial<TUrlProps>,
    options?: TLocationOptions,
  ) {
    return changeLocation(
        urlExtend(_globalLocation, extendsLocation), options,
    );
  }
  function replaceLocation(
    extendsLocation: Partial<TUrlProps>,
    options?: TLocationOptions,
  ) {
    return changeLocation(
        mergeLocation(_globalLocation, extendsLocation), options, 1,
    );
  }
  function pushHashLocation(
    child: Partial<TUrlProps>,
    options?: TLocationOptions
  ) {
    return pushLocation({
      child,
    }, options);
  }
  function storagePushHashLocation(
    child: Partial<TUrlProps>,
    options?: TLocationOptions
  ) {
    return storagePushLocation({
      child,
    }, options);
  }
  function replaceHashLocation(
    child: Partial<TUrlProps>,
    options?: TLocationOptions
  ) {
    return replaceLocation({
      child,
    }, options);
  }
  function backLocation() {
    history.back();
  }
  function LinkProvider({
    pushLocation,
    hasHash,
  }: {
    pushLocation: TPushLocation,
    hasHash?: boolean | number,
  }): React.ElementType {
    return forwardRef((props: TLinkProps, ref: any) => {
      const onClick = props.onClick || noop;
      const options = urlExtend(props.href, props.options);
      const url = options.href;
      const timeout = props.timeout || 0;
      const addition: Record<string, any> = url ? {
        href: (hasHash ? ('#' + url) : url),
      } : {};
      addition.ref = ref;
      addition.onClick = url ? (e: any) => {
        e.preventDefault && e.preventDefault();
        if (!hasDurationLink) {
          hasDurationLink = 1;
          onClick(e);
          timeout
            ? setTimeout(action, timeout)
            : action();
        }
        return false;
      } : onClick;
      function action() {
        hasDurationLink = 0;
        props.active || pushLocation(options);
      }
      return createElement(
          props.component || 'a',
          withoutProps(props, WITHOUT_FIELDS_LINK, addition),
      );
    });
  }
  function NavLinkProvider(
    Link: React.ElementType,
    $location: Store<any>,
  ): React.ElementType {
    return childClassOfReact(Component, (self) => {
      let subscription: (() => any) | 0;
      const setState = self.setState.bind(self);
      self.state = $location.getState();
      self.componentDidMount = () => {
        subscription || (subscription = $location.watch(setState));
      };
      self.componentWillUnmount = () => {
        subscription && (subscription(), subscription = 0); // eslint-disable-line
      };
      self.render = () => {
        const props = extend({}, self.props);
        const forwardedRef = props.forwardedRef;
        const {
          state,
        } = self;
        const path = state.path || '/';
        const matchs = urlExtend(props.href, props.options);
        const targetPath = matchs.path;
        const hasActive = path === targetPath
          && isMatch(state.query, matchs.query);

        if (forwardedRef) {
          delete props.forwardedRef;
          props.ref = forwardedRef;
        }

        if (
          hasActive || props.activeAsParent && path.startsWith( // eslint-disable-line
            targetPath.slice(-1) === '/' ? targetPath : (targetPath + '/'),
          )
        ) {
          props.active = hasActive;
          props.className = 'active ' + (props.className || '');
        }

        return createElement(Link, props);
      };
    }) as any;
  }

  function paramStorageProvider(
    $query: Store<TParams>,
    pushLocation: TPushLocation,
  ) {
    const $params = createStore({});
    const {
      emit,
    } = createApi($params, {
      emit: (_, payload: Record<string, any>) => payload,
    });

    let state = $query.getState();

    const instance = {
      set,
      get: (key: string) => state[key],
      remove: (key: string) => set(key),
      getKeys: () => keys(state),
      clear: () => {
        setState({});
        return instance;
      },
      watch: (watcher: (state: Record<string, any>) => any) => $params.watch(watcher),
    };

    
    function setState(query: Record<string, any>) {
      _locked = 1;
      pushLocation({query});
      changeState(query);
      _locked = 0;
    }
    function set(key: string, v?: any) {
      if (!isEqual(v, state[key])) {
        const nextState = mapValues(state);
        nextState[key] = v;
        setState(nextState);
      }
      return instance;
    }
    function changeState(nextState: Record<string, any>) {
      const prev = state;
      const exclude: Record<string, any> = {};
      const changed: Record<string, any> = {};
      let v: any, k: string;
      state = nextState;
      for (k in state) { // eslint-disable-line
        exclude[k] = 1; 
        isEqual(prev[k], v = state[k]) || (changed[k] = v);
      }
      for (k in prev) exclude[k] // eslint-disable-line
        || isEqual(prev[k], v = state[k]) || (changed[k] = v);
      for (k in changed) emit({ // eslint-disable-line
        key: k,
        value: changed[k],
      });
    }
    $query.watch((state) => {
      _locked || changeState(state || {});
    });
    return instance;
  }

  return {
    $location,
    $query,
    $path,
    $hashLocation,
    $hashQuery,
    $hashPath,
    LinkProvider,
    routerForObservableMapProvider,
    paramStorageProvider,
    routerByLocationProvider: routerByLocationProviderProvider($location),
    routerByHashLocationProvider:
      routerByLocationProviderProvider($hashLocation),
    paramStorage: paramStorageProvider($query, storagePushLocation),
    hashParamStorage:
      paramStorageProvider($hashQuery, storagePushHashLocation),
    Link,
    NavLink,
    HashLink,
    HashNavLink,
    getLocation: parseLocation,
    pushLocation,
    replaceLocation,
    pushHashLocation,
    replaceHashLocation,
    storagePushLocation,
    storagePushHashLocation,
    backLocation,
  };
};
