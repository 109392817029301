import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Ripple } from '../dumb/Ripple';


export const PageListBaseSortButton = React.memo((props: {
  className?: string,
  value: string,
  onChange: (field: string) => void,
  field: string,
}) => {
  const {
    value,
    field,
  } = props;

  const [p0, p1] = value.split('!');
  
  const currentField = p0 || p1;

  const isActive = currentField === field;
  const isNegative = !p0 && isActive;

  return (
    <Ripple
      className={'sq36 dF aiC jcC bgF.1 bgF.2(:h|.active) r5 ov dn '
        + (props.className || '')
        + (isActive ? ' active' : '')
        + (isNegative ? ' negative' : '')
      }
      onClick={() => {
        props.onChange(
          isActive
            ? (isNegative ? '' : ('!' + field))
            : field
        );
      }}
    >
      <div className='dF fxdR aiC jcC sq20 dn rlv rz90<.active rz-90<.active.negative'>
        <ArrowForwardIosIcon className='sq20-i'/>
      </div>
    </Ripple>
  );
});