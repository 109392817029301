import React from 'react';
import { useUnit } from 'effector-react';
import { createApi, createStore } from 'effector';

import {
  $customModalIndex,
  $customModals,
  TModalRenderBox,
  closeCustomModal,
  closeAllCustomModals,
} from './store';

export * from './store';


const $animatedModals = createStore<TModalRenderBox[]>([]);
const { setAnimatedModals } = createApi($animatedModals, {
  setAnimatedModals: (_, modals: TModalRenderBox[]) => modals,
});

$customModalIndex.watch((currentModalIndex: number) => {
  const customModals = $customModals.getState();
  const closedModals: TModalRenderBox[] = [];
  const newModals: TModalRenderBox[] = [];
  const animatedModalsOfNext: TModalRenderBox[] = [];
  const animatedModals = $animatedModals.getState();

  animatedModals.forEach((item) => {
    if (item.modalIndex > currentModalIndex) {
      animatedModalsOfNext.push({
        ...item,
        opened: false,
      });
      closedModals.push(item);
    } else {
      animatedModalsOfNext.push(item);
    }
  });

  customModals.slice(0, Math.min(customModals.length, currentModalIndex)).forEach((item) => {
    const { id } = item;

    if (!animatedModals.find((v) => v.id === id)) {
      animatedModalsOfNext.push(item);
      newModals.push(item);
    }
  });

  setAnimatedModals(animatedModalsOfNext);

  newModals.length &&
    setTimeout(() => {
      setAnimatedModals(
        $animatedModals.getState().map((item) => {
          const { id } = item;

          return newModals.find((v) => v.id === id)
            ? {
                ...item,
                opened: true,
              }
            : item;
        }),
      );
    }, 50);

  closedModals.length &&
    setTimeout(() => {
      setAnimatedModals(
        $animatedModals.getState().filter((item) => {
          const { id } = item;

          return !closedModals.find((v) => v.id === id);
        }),
      );
    }, 230);
});

export const AppCustomModals = React.memo(() => {
  const animatedModals = useUnit($animatedModals);

  return (
    <>
      {animatedModals.map((item) => {
        return (
          <item.component
            close={closeCustomModal}
            closeAll={closeAllCustomModals}
            key={item.id}
            modalIndex={item.modalIndex}
            opened={item.opened || false}
          />
        );
      })}
    </>
  );
});
