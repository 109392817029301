import React from "react";
import { useUnit } from 'effector-react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LINK_LOGIN, LINK_MAIN } from "./constants/links";
import { pushLocation } from "./utils/router";
import { $auth } from "./store/auth";
import { $route } from "./router";
import { Navigation } from "./components/smart/Navigation";
import { AppCustomModals } from "./services/customModals";


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const Router = React.memo(() => {
  const auth = useUnit($auth);
  const route = useUnit($route);
  const Main = route.main;
  const hasPublic = route.public;

  /*
  console.log('auth', {
    hasPublic,
    auth,
  });
  */

  if (auth) {
    if (hasPublic) {
      pushLocation({
        path: LINK_MAIN,
      });
      return null;
    }
  } else {
    if (!hasPublic) {
      pushLocation({
        path: LINK_LOGIN,
      });
      return null;
    }
  }

  const content = Main ? (
    <Main
      params={route.params}
      location={route.location}
    />
  ) : null;

  return hasPublic ? (
    <div className="abs s ovA">
      {content}
    </div>
  ) : (
    <div className="abs s">
      <div className="abs sl sv w200 ov ovyA bgF.1">
        <Navigation/>
      </div>
      <div className="abs sl200 sv sr ovA">
        {content}
      </div>
    </div>
  );
});

export const App = React.memo(() => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="h100vh w rlv bg2 cD">
        <Router />
        <AppCustomModals />
      </div>
    </ThemeProvider>
  );
});

export default App;