import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PagePlacesList } from './PlacesList';
import { PagePlacesEdit } from './PlacesEdit';

export const PagePlaces = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PagePlacesList}
      PageAdd={PagePlacesEdit}
      PageEdit={PagePlacesEdit}
    />
  );
});