import React from 'react';
import {
  Ripple,
  TRippleProps,
} from '../Ripple';


export const ButtonCancel = React.memo((props: TRippleProps) => {
  return (
    <Ripple
      {...props}
      className={`dF fxdR aiC jcS h36 pv5 ph15 r5 ov dn bg282828 f14 lh usN
        (o50|crA).disabled bg3:not[.disabled](:h|.active) `
        + (props.className || '')}
    />
  );
});