

export const isMatch = (src: any, matchs: any, depth?: number) => {
  return !isNotMatch(src, matchs, depth || 10);
};

function isNotMatch(src: any, matchs: any, depth: number) {
  if (src === matchs || depth < 0) return;
  let k,  t1 = typeof src, t2 = typeof matchs;
  if (t1 !== t2 || t1 !== 'object' || !src) return true;
  depth--;
  for (k in matchs) {
    if (isNotMatch(src[k], matchs[k], depth)) return true;
  }
}