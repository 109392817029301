import { TStory } from '../types';
import { cacheProvider } from './common';


export const {
  $items: $stories,
  itemsAPI: storiesAPI,
} = cacheProvider<TStory>([
  {
    id: "65ccc5c49a346bb12791f7e1",
    title: "Тайтл 1",
    isActive: true,
    regionId: "65ccc5c49a346bb12791f7e1",
    image: "1",
    position: 1
  },
  {
    id: "45ccc5c49a346bb12791f7e1",
    title: "Тайтл 2",
    isActive: true,
    regionId: "45ccc5c49a346bb12791f7e1",
    image: "3",
    position: 2
  },
]);
