import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageCelebrationEventsList } from './CelebrationEventsList';
import { PageCelebrationEventsEdit } from './CelebrationEventsEdit';

export const PageCelebrationEvents = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageCelebrationEventsList}
      PageAdd={PageCelebrationEventsEdit}
      PageEdit={PageCelebrationEventsEdit}
    />
  );
});