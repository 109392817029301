import {
  createStore,
  createApi,
  StoreWritable,
} from 'effector';
import noop from 'lodash/noop';
import { isDefined } from './isDefined';
import { isEqual } from './isEqual';


type TStore<A> = StoreWritable<A> & {
  setState: (v: A) => TStore<A>,
};

export type TStorage = {
  watch: (value: any) => (() => void),
  set: (key: string, value: any) => TStorage,
  get: (key: string) => any,
};

export const storeProvider = (storage: TStorage, prefix?: string) => {
  prefix || (prefix = '');
  const __on = storage.watch || noop;
  const __get = storage.get || noop;
  const __set = storage.set || noop;
  return <A>(
    name: string,
    defaultValue?: A
  ): TStore<A> => {
    name = prefix + name;
    function getInitialValue() {
      return normalize(__get(name));
    }
    function normalize(v: any) {
      return isDefined(v) ? v : defaultValue;
    }

    const $emitter = createStore<A>(getInitialValue());
    const {
      emit,
    } = createApi($emitter, {
      emit: (_: any, payload: any) => payload,
    });

    __on((e: any) => {
      e.key === name && emit(normalize(e.value));
    });
    ($emitter as any).setState = (v: A) => {
      __set(name, isEqual(defaultValue, v) ? null : v);
    };
    return $emitter as any;
  };
};