import React from 'react';
import { Checkbox, FormControlLabel, FormControlLabelProps } from '@mui/material';

export type TCheckboxAProps = Omit<FormControlLabelProps, "control"> & {
  /*
  onChange?: (e: any) => any,
  value?: boolean,
  className?: string,
  label: any,
  */
};

export const CheckboxA = React.memo((props: TCheckboxAProps) => {
  return (
    <FormControlLabel
      {...props}
      control={<Checkbox />}
    />
  );
});