
export const STORY_PAGE_TYPE_IMAGE = 'ImagePage';
export const STORY_PAGE_TYPE_VIDEO = 'VideoPage';

export const STORY_PAGE_TYPES = [
  {
    id: STORY_PAGE_TYPE_IMAGE,
  },
  {
    id: STORY_PAGE_TYPE_VIDEO,
  },
];

export const BUTTON_TYPE_NEXT_PAGE = 'NEXT_PAGE';
export const BUTTON_TYPE_REDIRECT = 'REDIRECT';
export const BUTTON_TYPE_HIDE = 'HIDE';

export const BUTTON_TYPES = [
  {
    id: BUTTON_TYPE_NEXT_PAGE,
  },
  {
    id: BUTTON_TYPE_REDIRECT,
  },
  {
    id: BUTTON_TYPE_HIDE,
  },
];

export const BUTTON_TYPE_DEFAULT = BUTTON_TYPE_NEXT_PAGE;

export const SECONDS_DURATION_ITEMS = [
  {
    id: 15,
    name: 15,
  },
  {
    id: 30,
    name: 30,
  },
  {
    id: 60,
    name: 60,
  },
];

export const DEFAULT_SECONDS_DURATION = SECONDS_DURATION_ITEMS[0]?.id;