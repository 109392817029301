
import isNumber from 'lodash/isNumber';
import {
  isObjectTarget,
} from './isObjectTarget';
import {
  half,
} from './half';
import {
  tryJsonParse as decode,
} from './tryJsonParse';

const expSpace = /\+/g;
const expBrackets = /\[(.*?)\]/g;
const expVarname = /(.+?)\[/;


export type TParams = Record<string, any>;


export const unparam = (s: string): TParams => {
  const a = half(s, '?', 1)[1].split('&');
  const l = a.length;
  const r: Record<string, any> = {};
  let w: any, t: any, k: any, v: any, _v: any, b: any, c: any, d: any, j: any, n: any, ni: any, q: any, i = 0; 
  if (l < 1) return r;
  for (;i < l; i++) {
    k = (w = half(a[i], '='))[0];
    if (!k) continue;
    isNumber(_v = decode(decodeURIComponent(v = w[1].replace(expSpace, ' '))))
      || (v = _v);
    b = [];
    while (w = expBrackets.exec(k)) b.push(w[1]); // eslint-disable-line
    if ((c = b.length) < 1) {
      r[k] = v;
      continue;
    }
    c--;
    w = expVarname.exec(k);
    if (!w || !(k = w[1]) || w.length < 1) continue;
    if (!isObjectTarget(d = r[k])) d = r[k] = {};
    for (j = 0, q = b.length; j < q; j++) {
      if ((w = b[j]).length < 1) {
        w = 0;
        for (n in d) {
          if (!isNaN(ni = parseInt(n)) && ni >= 0
            && (ni % 1 === 0) && ni >= w) w = ni + 1;
        }
      }
      j == c ? (d[w] = v) : (d = isObjectTarget(t = d[w]) ? t : (d[w] = {})); // eslint-disable-line
    }
  }
  return r;
};