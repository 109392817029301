import { TMainBanner } from '../types';
import { cacheProvider } from './common';


export const {
  $items: $mainBanners,
  itemsAPI: mainBannersAPI,
} = cacheProvider<TMainBanner>([
  /*
  {
    id: "65ccc5c49a346bb12791f7e1",
    title: "Тайтл 1",
    buttonText: "Текст кнопки 1",
    image: "1",
    url: "randomUrl1",
    position: 1
  },
  {
    id: "45ccc5c49a346bb12791f7e1",
    title: "Тайтл 2",
    buttonText: "Текст кнопки 2",
    image: "2",
    url: "randomUrl2",
    position: 2
  },
  */
]);
