import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $regions, regionsAPI } from '../../../../store/regions';
import { LINK_REGIONS_ADD } from '../../../../constants/links';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiRegions } from '../../../../api/apiRegions';
import { RegionsItem } from './RegionsItem';
import { SortHeader } from './SortHeader';


const handleRemove = async (params: {
  ids: string[],
}) => {
  await apiRegions.remove(params)
  regionsAPI.remove(params);
};

export const PageRegionsList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const items = useUnit($regions);

  useEffect(() => {
    setLoading(true);
    apiRegions.getList().then((response) => {
      regionsAPI.set(response?.data || []);
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <PageListBaseB
      titleText="Регионы"
      addLink={LINK_REGIONS_ADD}
      addText="Добавить регион"
      items={items}
      Item={RegionsItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});