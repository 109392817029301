import React, { useCallback, useEffect, useMemo, useState } from 'react';
import trim from 'lodash/trim';
import { TFile, TPlace } from '../../../types';
import { useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { backLocation } from '../../../utils/router';
import { TRouteProps } from '../../../utils/routerProvider';
import { apiPlaces } from '../../../api/apiPlaces';
import { apiImagesGetUrlById } from '../../../api/apiImages';
import { $places, placesAPI } from '../../../store/places';
import { TextFieldA } from '../../dumb/TextField/A';
import { CheckboxA } from '../../dumb/Checkbox/A';
import { ButtonB } from '../../dumb/Button/B';
import { WrapperWithLoading } from '../../dumb/WrapperWithLoading';
import { PhotoSelectA } from '../../smart/PhotoSelectA';
import { SelectA } from '../../dumb/Select/A';
import { SelectSmartRegionId } from '../../smart/SelectSmartRegionId';
import { SelectImageArea, TSelectImageValue } from '../../dumb/SelectImageArea';
import { HeaderA } from '../../smart/Header/A';
import { toObject } from '../../../utils/toObject';
import { toFloat } from '../../../utils/toFloat';


const AREA_FIELDS = [
  {
    id: 'uniqueSection',
    name: 'Превью в блоках "Избраннные места"(профиль), "Места рядом"(место) и "Уникальные места"(регион)',
    width: 280,
    height: 320,
  },
  {
    id: 'favoritePage',
    name: 'Превью на странице "Избраннные места" и "Места рядом"',
    width: 400,
    height: 400,
  },
  {
    id: 'desktop',
    name: 'Основное изображение на дестопе',
    width: 1260,
    height: 840,
  },
  {
    id: 'mobile',
    name: 'Основное изображение на смартфоне',
    width: 720,
    height: 840,
  },
];

export const PagePlacesEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($places, id, () => ({
    id,
    name: '',
    details: '',
    location: null,
    communityUrl: '',
    regionId: '',
    selectedImage: '',
    images: [],
    isActive: false,
    cityName: '',
    building: '',
    street: '',
    lat: 0,
    lon: 0,
    selectedImageParams: {},
  }), (v) => ({
    ...v,
    lat: '' + v.lat,
    lon: '' + v.lon,
  }));

  const [loading, setLoading] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  const [imageParams, _setImageParams] = useState<TSelectImageValue>({
    areas: {},
    size: [1, 1],
  });

  const setImageParams = useCallback((selectedImageParams: any) => {
    _setImageParams(selectedImageParams);
    setItemOfOrigin((v) => {
      return {
        ...v,
        selectedImageParams: {
          ...toObject(v.selectedImageParams),
          ...selectedImageParams,
        },
      };
    });
  }, []);


  const {
    name,
    selectedImage,
    regionId,
  } = itemOfOrigin;


  useEffect(() => {
    id && apiPlaces.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TPlace;
      let selectedImageParams = toObject(data.selectedImageParams || '{}');
      selectedImageParams = {
        ...selectedImageParams,
        areas: selectedImageParams.areas || {},
        size: selectedImageParams.size || [1, 1],
      };
      
      setItemOfOrigin({
        ...data,
        ...(data.location || {}),
        selectedImageParams,
      });
      _setPhotos((data.images || []).map((id: string) => {
        const dataUrl = apiImagesGetUrlById(id);
        return {
          id,
          dataUrl,
          previewUrl: dataUrl,
        };
      }));
      _setImageParams(selectedImageParams);
    });
  }, [id]);

  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    setItemOfOrigin({
      images: items.map((v) => v.id),
    });
  }, []);

  const [clicked, setClicked] = useState(false);
  const invalidName = useMemo(() => !trim(name), [name]);

  const invalidSelectedImage = !selectedImage;

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !photos.length;

  const invalidRegionId = !regionId;

  const disabled = invalidName
    || invalidRegionId
    || invalidSelectedImage
    || errorOfPhotos
    || errorOfPhotosExist;

  const handleChangeName = useCallback((e: any) => {
    setItemOfOrigin({
      name: e.target.value,
    });
  }, []);

  const handleChangeDetails = useCallback((e: any) => {
    setItemOfOrigin({
      details: e.target.value,
    });
  }, []);

  const handleChangeRegionId = useCallback((e: any) => {
    setItemOfOrigin({
      regionId: e.target.value,
    });
  }, []);

  const handleChangeCommunityUrl = useCallback((e: any) => {
    setItemOfOrigin({
      communityUrl: e.target.value,
    });
  }, []);

  const handleChangeSelectedImage = useCallback((e: any) => {
    setItemOfOrigin({
      selectedImage: e.target.value,
    });
  }, []);

  const handleChangeIsActive = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);

  const handleChangeLat = useCallback((e: any) => {
    setItemOfOrigin({
      lat: (e.target.value) as any,
    });
  }, []);
  const handleChangeLon = useCallback((e: any) => {
    setItemOfOrigin({
      lon: (e.target.value) as any,
    });
  }, []);
  const handleChangeBuilding = useCallback((e: any) => {
    setItemOfOrigin({
      building: e.target.value,
    });
  }, []);
  const handleChangeStreet = useCallback((e: any) => {
    setItemOfOrigin({
      street: e.target.value,
    });
  }, []);
  const handleChangeCityName = useCallback((e: any) => {
    setItemOfOrigin({
      cityName: e.target.value,
    });
  }, []);

  const handleSubmit = useCallback(() => {
    setItemOfOrigin((data) => {
      if (disabled) {
        setClicked(true);
        return data;
      }
      
      setLoading(true);

      (async () => {
        const id = data.id;

        data = {
          ...data,
          name: trim(data.name),
          details: trim(data.details),
          communityUrl: trim(data.communityUrl),
          regionId: trim(data.regionId),
          cityName: trim(data.cityName),
          building: trim(data.building),
          street: trim(data.street),
          lat: toFloat(data.lat),
          lon: toFloat(data.lon),
        };

        const params = {
          ...data,
          selectedImageParams: JSON.stringify(data.selectedImageParams),
        };

        delete (data as any).location;

        if (id) {
          const {
            error,
          } = await apiPlaces.change({
            id,
            params,
          });

          if (error) {
            throw error;
          }


          placesAPI.edit({
            id,
            data: {
              ...data,
              location: {
                building: data.building,
                street: data.street,
                cityName: data.cityName,
                lat: data.lat,
                lon: data.lon,
              },
            },
          });

        } else {
          const {
            error,
            data: _data,
          } = await apiPlaces.create({
            params,
          });

          if (error) {
            throw error;
          }

          if (_data) {
            data = {
              ..._data,
              ...(_data.location || {
                cityName: '',
                building: '',
                street: '',
                lat: 0,
                lon: 0,
              }),
              selectedImageParams: toObject(data.selectedImageParams),
            };
          }
  
          placesAPI.add(data);

          /*
          replaceLocation({
            path: LINK_PLACES_EDIT + data.id,
          });
          */
        }

        backLocation();

      })().then(() => {
        setLoading(false);
      });

      return data;
    });

  }, [disabled]);

  const dataUrl = useMemo(() => apiImagesGetUrlById(selectedImage), [selectedImage]);
  

  return (
    <div className='sq'>
      <HeaderA id={id}>
        {id ? 'Редактирование места:' : 'Новое место:'} {name}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <CheckboxA
          label="Активный"
          checked={itemOfOrigin.isActive}
          onChange={handleChangeIsActive}
        />
        <TextFieldA
          className='mt15'
          label="Название"
          value={name}
          onChange={handleChangeName}
          error={invalidName && clicked}
        />
        <TextFieldA
          className='mt10'
          label="Детали"
          value={itemOfOrigin.details}
          onChange={handleChangeDetails}
          multiline
          rows={4}
        />
        <TextFieldA
          className='mt10'
          label="communityUrl"
          value={itemOfOrigin.communityUrl}
          onChange={handleChangeCommunityUrl}
        />
        <SelectSmartRegionId
          className='mt10'
          label="Регион"
          value={regionId}
          onChange={handleChangeRegionId}
          error={invalidRegionId && clicked}
        />
        <div className='mt20'>
          Локация
        </div>
        <TextFieldA
          className='mt10'
          label="Lat"
          value={itemOfOrigin.lat}
          onChange={handleChangeLat}
        />
        <TextFieldA
          className='mt10'
          label="Lon"
          value={itemOfOrigin.lon}
          onChange={handleChangeLon}
        />
        <TextFieldA
          className='mt10'
          label="CityName"
          value={itemOfOrigin.cityName}
          onChange={handleChangeCityName}
        />
        <TextFieldA
          className='mt10'
          label="Street"
          value={itemOfOrigin.street}
          onChange={handleChangeStreet}
        />
        <TextFieldA
          className='mt10'
          label="Building"
          value={itemOfOrigin.building}
          onChange={handleChangeBuilding}
        />

        <div className='mt20'>
          Изображения
        </div>
        <PhotoSelectA
          className='mt10'
          error={errorOfPhotos || (clicked && errorOfPhotosExist)}
          limit={50}
          value={photos}
          onChange={setPhotos}
        />
        <SelectA
          className='mt10'
          label="Выбранные изображения"
          value={selectedImage}
          onChange={handleChangeSelectedImage}
          items={photos}
          error={invalidSelectedImage && clicked}
        />
        <div className='mt20'>
          Область изображения для превью
        </div>
        <SelectImageArea
          className='mt10'
          imageUrl={dataUrl}
          value={imageParams}
          onChange={setImageParams}
          fields={AREA_FIELDS}
        />
        <ButtonB
          className='w200 mt15'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});