import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';


export const TextFieldA = React.memo((props: TextFieldProps) => {
  return (
    <div className={props.className || ''}>
      <TextField
        {...props}
        className='w'
        variant={props.variant || 'filled'}
        helperText={props.helperText || (props.error ? 'Поле обязательно' : '')}
      />
    </div>
  );
});