import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TCelebrationEmoji, TCelebrationEmojiState } from '../../../../../types';
import { Ratio } from '../../../../dumb/Ratio';
import { Ripple } from '../../../../dumb/Ripple';
import { WrapperWithLoading } from '../../../../dumb/WrapperWithLoading';
import { apiCelebrationEmojis } from '../../../../../api/apiCelebrationEmojis';
import { withState } from '../../../../../utils/withState';
import { SelectEmojiItem } from './SelectEmojiItem';
import { CelebrationEmojiForm } from '../../../../smart/CelebrationEmojiForm';


export type TSelectEmojiPagesProps = {
  className?: string,
  celebrationId?: string,
};

export type TSelectEmojiPagesState = {
  pages: TCelebrationEmoji[],
  emojiId: string,
  emojiData: TCelebrationEmojiState,
  loading: boolean,
  opened: boolean,
};


export const SelectEmojis = withState<TSelectEmojiPagesProps, TSelectEmojiPagesState>((setState, self) => {
  function emojiDataInitial() {
    return {
      id: '',
      isActive: true,
      celebrationId: self.props.celebrationId || '',
      position: '',
      vkEmojiId: '',
      image: '',
      text: '',
    };
  }
  
  self.state = {
    pages: [],
    emojiId: '',
    emojiData: emojiDataInitial(),
    loading: true,
    opened: false,
  };
  
  function handleAddEmoji() {
    setState({
      opened: true,
      emojiId: '',
      emojiData: emojiDataInitial(),
    });
  }

  function handleSelect(id: string) {
    const data = self.state.pages.find((v) => v.id === id) || emojiDataInitial();

    setState({
      opened: true,
      emojiId: id,
      emojiData: {
        ...data,
        vkEmojiId: '' + (data.vkEmojiId || 0),
        position: '' + (data.position || 0),
      },
    });
  }

  async function handleRemove(params: {
    ids: string[],
  }) {
    setState({
      loading: true,
    });
    await apiCelebrationEmojis.remove(params);

    const {
      ids,
    } = params;
    setState({
      loading: false,
      pages: self.state.pages
        .filter((page) => !ids.includes(page.id || '')),
    });
  }

  function handleChangeEmoji(emojiData: Partial<TCelebrationEmojiState> | ((v: TCelebrationEmojiState) => Partial<TCelebrationEmojiState>)) {
    const prev = self.state.emojiData;
    
    setState({
      emojiData: {
        ...prev,
        ...(typeof emojiData === 'function' ? emojiData(prev) : emojiData),
      },
    });
  }

  function handleBack() {
    setState({
      opened: false,
      emojiId: '',
      emojiData: emojiDataInitial(),
    });
  }

  async function handleSave() {
    const {
      state,
      props,
    } = self;
    const {
      emojiId,
      emojiData,
      pages,
    } = state;


    const params = {
      ...emojiData,
      position: parseInt(emojiData.position as any || '0'),
      vkEmojiId: parseInt(emojiData.vkEmojiId as any || '0'),
      celebrationId: props.celebrationId || '',
    };

    /*
    console.log('params', {
      emojiPageId,
      params,
    });
    */

    if (emojiId) {
      apiCelebrationEmojis.change({
        id: emojiId,
        params,
      });

      setState({
        opened: false,
        emojiId: '',
        emojiData: emojiDataInitial(),
        pages: pages.map((v) => {
          return v.id === emojiId ? {
            ...params,
            id: emojiId,
          } : v;
        }),
      });

    } else {
      const response = await apiCelebrationEmojis.create({
        params,
      });
      const data = response?.data;

      data && setState({
        opened: false,
        emojiId: '',
        emojiData: emojiDataInitial(),
        pages: [...pages, data],
      });
    }

  }

  const {
    localEffect,
    localMemo,
  } = self;


  return (props, state) => {
    const {
      celebrationId,
    } = props;
    const {
      pages,
      emojiData,
    } = state;

    localEffect(async () => {
      const response = await apiCelebrationEmojis.getList({
        query: {
          celebrationId,
          celebration_id: celebrationId,
        },
      });
      setState({
        pages: response?.data || [],
        loading: false,
      });
    }, [celebrationId]);

    const items = localMemo(() => {
      const items = [...pages];
      items.sort((a, b) => a.position - b.position);
      return items;
    }, [pages]);


    return (
      <WrapperWithLoading
        className={'SelectEmojiPages w ' + (props.className || '')}
        loading={state.loading}
      >
        <div className='rlv'>
          <div className='ov ovxA'>
            <div className='dF aiC jcFS mh-4 (lt|p4)>1'>
              <div>
                <Ripple
                  className='w(|min)115 r12 ov bgF.1 bgF.2:h dn'
                  onClick={handleAddEmoji}
                >
                  <Ratio
                    className='dF aiC jcC bxz aiC jcC ph10'
                    width={115}
                    height={58}
                  >
                    <AddIcon className='f50-i'/>
                  </Ratio>
                </Ripple>
                <div className='mt5 h18'/>
              </div>
              {items.map((page) => {
                return (
                  <SelectEmojiItem
                    key={page.id}
                    data={page}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                  />
                );
              })}
            </div>
          </div>
          {
            state.opened ? (
              <CelebrationEmojiForm
                className='abs sh-16 sb-16 r10 bg1 ov ovyA z2 hmin600'
                data={emojiData}
                onChange={handleChangeEmoji}
                onBack={handleBack}
                onSave={handleSave}
              />
            ) : null
          }
        </div>
      </WrapperWithLoading>
    );
  };
});
