import React from 'react';
import {
  Ripple,
  TRippleProps,
} from '../Ripple';


export const ButtonC = React.memo((props: TRippleProps) => {
  return (
    <Ripple
      {...props}
      className={`dF fxdR aiC jcS h36 pv5 ph15 r5 ov dn bgF.1 f14 lh usN
        (o50|crA).disabled bgF.2:not[.disabled](:h|.active) `
        + (props.className || '')}
    />
  );
});