import React from 'react';
import { backLocation } from '../../../utils/router';
import { ButtonBack } from '../../dumb/Button/Back';

export type THeaderAProps = {
  className?: string;
  id?: string;
  onBack?: (...args: any[]) => any;
  children: any;
};

export const HeaderA = React.memo((props: THeaderAProps) => {
  const {
    id,
  } = props;
  
  return (
    <div
      className={'h50 dF fxdR aiC jcSB f18 pl5 pr15 rlv '
        + (props.className || '')
    }>
      <div className='dF fxdR aiC jcS fx1 wmin0'>
        <div className='pr5'>
          <ButtonBack
            onClick={props.onBack || backLocation}
          />
        </div>
        <div className='fx1 wmin0 ov tov wsNW usN lh'>
          {props.children}
        </div>
      </div>
      {
        id ? (
          <div
            className='abs sl50 st5 f10 lh'
          >ID: {id}</div>
        ) : null
      }
    </div>
  );
});