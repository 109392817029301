import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $places, placesAPI } from '../../../../store/places';
import { LINK_PLACES_ADD } from '../../../../constants/links';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiPlaces } from '../../../../api/apiPlaces';
import { apiRegions } from '../../../../api/apiRegions';
import { $regions, regionsAPI } from '../../../../store/regions';
import { SortHeader } from './SortHeader';
import { PlacesItem } from './PlacesItem';


const handleRemove = async (params: {
  ids: string[],
}) => {
  await apiPlaces.remove(params)
  placesAPI.remove(params);
};


export const PagePlacesList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const regions = useUnit($regions);
  const items = useUnit($places).map((place) => {
    const {
      regionId,
    } = place;

    return {
      ...place,
      regionName: regions.find((v) => v.id === regionId)?.name || ''
    };
  });


  useEffect(() => {
    setLoading(true);
    apiRegions.getList().then((response) => {
      regionsAPI.set(response?.data || []);
    });

    apiPlaces.getList().then((response) => {
      placesAPI.set((response?.data || []).map((v) => {
        return {
          ...v,
          ...(v.location || {
            cityName: '',
            building: '',
            street: '',
            lat: '0' as any,
            lon: '0' as any,
          }),
        };
      }));
      setLoading(false);
    });
  }, [setLoading]);


  return (
    <PageListBaseB
      titleText="Места"
      addLink={LINK_PLACES_ADD}
      addText="Добавить место"
      items={items}
      Item={PlacesItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});
