import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  TRippleProps,
} from '../Ripple';
import { ButtonIcon } from './Icon';


export const ButtonBack = React.memo((props: TRippleProps) => {
  return (
    <ButtonIcon
      {...props}
    >
      <ArrowBackIcon/>
    </ButtonIcon>
  );
});