import React from 'react';
import { Loading } from './Loading';


export const WrapperWithLoading = React.memo(({
  loading,
  children,
  inner,
  ...props
}: {
  className?: string,
  loading?: boolean,
  children?: any,
  inner?: any,
}) => {
  inner = inner || {};

  return (
    <div
      {...props}
      className={'rlv ' + (props.className || '')}
    >
      <div
        {...inner}
        className={'ftBlur4.loading dn hmin40 '
          + (loading ? 'loading ' : '')
          + (inner?.className || '')
        }
      >{children || null}</div>
      {loading ? (<Loading/>) : null}
    </div>
  );
});