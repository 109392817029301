import React, { useCallback, useEffect, useMemo, useState } from 'react';
import trim from 'lodash/trim';
import { TFile, TStory } from '../../../../types';
import { apiImagesGetUrlById } from '../../../../api/apiImages';
import { apiStories } from '../../../../api/apiStories';
import { useItemFromStoreById } from '../../../../utils/getItemFromStoreById';
import { backLocation } from '../../../../utils/router';
import { $stories, storiesAPI } from '../../../../store/stories';
import { TextFieldA } from '../../../dumb/TextField/A';
import { ButtonB } from '../../../dumb/Button/B';
import { TRouteProps } from '../../../../utils/routerProvider';
import { WrapperWithLoading } from '../../../dumb/WrapperWithLoading';
import { PhotoSelectA } from '../../../smart/PhotoSelectA';
import { SelectSmartRegionId } from '../../../smart/SelectSmartRegionId';
import { CheckboxA } from '../../../dumb/Checkbox/A';
import { HeaderA } from '../../../smart/Header/A';
import { SelectStoryPages } from './SelectStoryPages';
import { SelectImageArea, TSelectImageValue } from '../../../dumb/SelectImageArea';
import { toObject } from '../../../../utils/toObject';


const AREA_FIELDS = [
  {
    id: 'storiesSection',
    name: 'Превью в блоке региона',
    width: 664,
    height: 234,
  },
];


export const PageStoriesEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($stories, id, () => ({
    id,
    title: '',
    image: '',
    regionId: '',
    isActive: false,
    position: 0,
    selectedImageParams: {},
  }), (v) => ({
    ...v,
    position: '' + v.position,
  }));

  const [loading, setLoading] = useState(false);
  const {
    title,
    image,
    regionId,
  } = itemOfOrigin;

  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  const [imageParams, _setImageParams] = useState<TSelectImageValue>({
    areas: {},
    size: [1, 1],
  });

  const setImageParams = useCallback((selectedImageParams: any) => {
    _setImageParams(selectedImageParams);
    setItemOfOrigin((v) => {
      return {
        ...v,
        selectedImageParams: {
          ...toObject(v.selectedImageParams),
          ...selectedImageParams,
        },
      };
    });
  }, []);

  useEffect(() => {
    id && apiStories.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TStory;
      let selectedImageParams = toObject(data.selectedImageParams || '{}');

      selectedImageParams = {
        ...selectedImageParams,
        areas: selectedImageParams.areas || {},
        size: selectedImageParams.size || [1, 1],
      };

      const image = data.image;
      const dataUrl = image ? apiImagesGetUrlById(image) : '';
      setItemOfOrigin({
        ...data,
        selectedImageParams,
      });
      _setPhotos(image ? [
        {
          id: image,
          dataUrl,
          previewUrl: dataUrl,
        },
      ] : []);
      _setImageParams(selectedImageParams);
    });
  }, [id]);

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !image;

  const invalidTitle = useMemo(() => !trim(title), [title]);

  const invalidRegionId = !regionId;

  const disabled = invalidTitle
    || invalidRegionId
    || errorOfPhotos
    || errorOfPhotosExist;

  
  const handleChangeIsActive = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);

  const handleChangeTitle = useCallback((e: any) => {
    setItemOfOrigin({
      title: e.target.value,
    });
  }, []);

  const handleChangeRegionId = useCallback((e: any) => {
    setItemOfOrigin({
      regionId: e.target.value,
    });
  }, []);

  const handleChangePosition = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        position: e.target.value.replace(/[^0-9]/gim, ''),
      };
    });
  }, []);

  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    setItemOfOrigin({
      image: items[0]?.id || '',
    });
  }, []);

  const handleSubmit = useCallback(() => {
    setItemOfOrigin((data) => {
      if (disabled) {
        setClicked(true);
        return data;
      }

      setLoading(true);
      (async () => {
        const id = data.id;

        data = {
          ...data,
          title: trim(data.title),
          image: trim(data.image),
          position: parseInt(data.position as any),
        };

        const params = {
          ...data,
          selectedImageParams: JSON.stringify(data.selectedImageParams),
        };

        if (id) {
          await apiStories.change({
            id,
            params,
          });

          storiesAPI.edit({
            id,
            data,
          });

        } else {
          const response = await apiStories.create({
            params,
          });

          data = response.data || data;

          storiesAPI.add(data);
        }

        backLocation();

      })().then(() => {
        setLoading(false);
      });
      
      return data;
    });
    
  }, [disabled]);

  const dataUrl = useMemo(() => apiImagesGetUrlById(image), [image])
  
  return (
    <div className='sq'>
      <HeaderA id={id}>
        {id ? 'Редактирование сторис:' : 'Новый сторис:'} {title}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <CheckboxA
          label="Активный"
          checked={itemOfOrigin.isActive}
          onChange={handleChangeIsActive}
        />
        <PhotoSelectA
          className='mt10'
          error={clicked && errorOfPhotosExist}
          limit={1}
          value={photos}
          onChange={setPhotos}
        />
        <TextFieldA
          className='mt15'
          label="Название"
          value={title}
          onChange={handleChangeTitle}
          error={invalidTitle && clicked}
        />
        {
          id ? (
            <TextFieldA
              className='mt15'
              label="Position"
              value={itemOfOrigin.position}
              onChange={handleChangePosition}
            />
          ) : null
        }
        <SelectSmartRegionId
          className='mt10'
          label="Регион"
          value={regionId}
          onChange={handleChangeRegionId}
          error={invalidRegionId && clicked}
        />

        <div className='mt20'>
          Область изображения для превью
        </div>
        <SelectImageArea
          className='mt10'
          imageUrl={dataUrl}
          value={imageParams}
          onChange={setImageParams}
          fields={AREA_FIELDS}
        />

        <ButtonB
          className='w200 mt30'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>

        {
          id ? (
            <>
              <div className='mt30'>
                Страницы этой истории
              </div>
              <SelectStoryPages
                storyId={id}
              />
            </>
          ) : null
        }
      </WrapperWithLoading>
    </div>
  );
});