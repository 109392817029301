import React, { useMemo } from 'react';

export type TRatioProps = {
  className?: string;
  width: number;
  height: number;
  children?: any;
};

export const Ratio = React.memo((props: TRatioProps) => {
  const heightInPercent = props.height / props.width;
  const style = useMemo(
    () => ({
      paddingTop: `${100 * heightInPercent}%`,
    }),
    [heightInPercent],
  );

  return (
    <div className='w dB rlv' style={style}>
      <div className={'abs s ' + (props.className || '')}>{props.children || null}</div>
    </div>
  );
});
