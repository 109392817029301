import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { LINK_CELEBRATIONS_ADD } from '../../../../constants/links';
import type { TRouteProps } from '../../../../utils/routerProvider';
import { PageListBaseB } from '../../../smart/PageListBaseB';
import { apiCelebrations } from '../../../../api/apiCelebrations';
import { $celebrations, celebrationsAPI } from '../../../../store/celebrations';
import { CelebrationsItem } from './CelebrationsItem';
import { SortHeader } from './SortHeader';


const handleRemove = async (params: {
  ids: string[],
}) => {
  await apiCelebrations.remove(params)
  celebrationsAPI.remove(params);
};

export const PageCelebrationsList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const items = useUnit($celebrations);

  useEffect(() => {
    setLoading(true);
    apiCelebrations.getList().then((response) => {
      celebrationsAPI.set(response?.data || []);
      setLoading(false);
    });    
  }, []);

  return (
    <PageListBaseB
      titleText="Празднования"
      addLink={LINK_CELEBRATIONS_ADD}
      addText="Добавить празднование"
      items={items}
      Item={CelebrationsItem}
      onRemove={handleRemove}
      loading={loading}
      SortComponent={SortHeader}
    />
  );
});