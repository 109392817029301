import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageInterestingBannersList } from './InterestingBannersList';
import { PageInterestingBannersEdit } from './InterestingBannersEdit';


export const PageInterestingBanners = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageInterestingBannersList}
      PageAdd={PageInterestingBannersEdit}
      PageEdit={PageInterestingBannersEdit}
    />
  );
});