


export const halfProvider = (indexOf: (input: string) => number) => {
  return (input: string, separator: string, right?: boolean | number) => {
    const i = indexOf.call(input, separator);
    return i < 0
      ? (right ? ['', input, ''] : [input, '', ''])
      : [input.substr(0, i), input.substr(i + separator.length), separator];
  };
};

export const half = halfProvider(''.indexOf);
export const halfLast = halfProvider(''.lastIndexOf);