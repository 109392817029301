import React, { useMemo } from 'react';

import { getVKVideoUrl } from '../../utils/vkVideoUrlParse';

export type TVKVideoProps = {
  src: string;
  className?: string;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
};

export const VKVideo = React.memo((props: TVKVideoProps) => {
  const src = props.src || '';
  const autoPlay = props.autoPlay || false;
  const loop = props.loop || false;
  const controls = props.controls || false;

  const vkVideoUrl = useMemo(
    () =>
      getVKVideoUrl(src, {
        autoplay: autoPlay ? 1 : 0,
        loop: loop ? 1 : 0,
        controls: controls ? 1 : 0,
      }),
    [src, autoPlay, loop, controls],
  );

  return vkVideoUrl ? (
    <iframe
      allowFullScreen
      allow='autoplay; encrypted-media; fullscreen; picture-in-picture;'
      className={'b bc00 ' + (props.className || '')}
      src={vkVideoUrl}
    />
  ) : (
    <video {...props} />
  );
});
