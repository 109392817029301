import WarningIcon from '@mui/icons-material/Warning';
import { TPushCustomModalAcceptParams, pushCustomModalAccept } from './pushCustomModalAccept';


export const pushCustomModalError = (params: TPushCustomModalAcceptParams) => {
  return pushCustomModalAccept({
    ...params,
    icon: <WarningIcon className="sq40*2 dB*2"/>,
    title: params.title || 'Произошла ошибка',
    cancelText: 'Закрыть',
  });
};
