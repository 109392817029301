import React, { useState } from 'react';
import EastIcon from '@mui/icons-material/East';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TStoryPage } from '../../../../types';
import { Ratio } from '../../../dumb/Ratio';
import { Ripple } from '../../../dumb/Ripple';
import { VKVideo } from '../../../dumb/VKVideo';
import { apiImagesGetUrlById } from '../../../../api/apiImages';
import { pushCustomModalRemove } from '../../../../services/customModals/modals/pushCustomModalRemove';
import { STORY_PAGE_TYPE_VIDEO } from './constants';


export type TSelectStoryPagesItemProps = {
  data: TStoryPage,
  onSelect: (id: string) => any,
  onRemove: (params: {
    ids: string[],
  }) => any,
};

export const SelectStoryPagesItem = React.memo((props: TSelectStoryPagesItemProps) => {
  const {
    data,
  } = props;
  const id = data.id || '';

  const isVideo = data.pageType === STORY_PAGE_TYPE_VIDEO;
  const [openedVideo, setOpenedVideo] = useState(false); 

  return (
    <div>
      <Ripple
        className='w(|min)360 bg0'
        onClick={() => {
          props.onSelect(id);
        }}
      >
        <Ratio
          className='dF aiC jcC r32 ov'
          width={360}
          height={664}
        >
          <img
            className='dB sq ofCover'
            src={apiImagesGetUrlById(data.image)}
          />
          <div
            className='abs s cF dF fxdC aiS jcFS bg0.46p22%-0.09p31%-0.0p62%-0.6p75%'
          >
            <div className='w pt70 ph14 rlv'>
              <div
                className='f37 fw6'
              >{data.title}</div>
              <div
                className='f21 fw6'
              >{data.subTitle}</div>
              <div className='abs st15 sh14 f12'>
                Длительность: {data.isInfinityDuration ? 'бесконечная' : (
                  <>
                    {data.secondsDuration} сек.
                  </>
                )}
              </div>
            </div>
            <div className='fx1 hmin0'/>
            {isVideo ? (
              <div className='abs s dF fxdR aiC jcC'>
                <Ripple
                  className='sq92 r ov dn bgD bgF:h c0 dF fxdR aiC jcC z1'
                  onClick={() => {
                    setOpenedVideo((v) => !v);
                  }}
                >
                  <PlayArrowIcon className='dB sq50-i'/>
                </Ripple>
              </div>
            ) : (
              <div className='p14 f16 fw6 lh20 lts-.31px'>
                {data.description}
              </div>
            )}
          </div>
        </Ratio>
        <div className='p14 pb50 bg0 dF fxdR aiC jcFS rlv'>
          <div className='fx1 pr5'>
            <div className='h36 w dF fxfR aiC jcC bgF c0 f15 wf5 lh tc r8'>
              <div>{data.buttonText}</div>
              <EastIcon className='ml6 f18-i'/>
            </div>
          </div>
          <div className='sq36 bgF.1 r8'/>
          <div className='abs sh14 sb15 f12 lh'>
            <div>buttonType: {data.buttonType}</div>
            <div className='ov tov w wsNW mt2'>buttonUrl: {data.buttonUrl}</div>
          </div>
        </div>
        <div className='abs s bgF.1<1:h dn'/>
        <Ripple
          className='abs st15 sr15 sq50 dF fxdR aiC jcC ov r bg4 bg6:h dn cF z1'
          onClick={() => {
            pushCustomModalRemove({
              onAccept: () => {
                props.onRemove({
                  ids: [id],
                });
              },
            });
          }}
        >
          <DeleteOutlineIcon/>
        </Ripple>
        {
          openedVideo ? (
            <div className='abs s bg0 z3'>
              <VKVideo
                className='dB sq'
                src={data.videoUrl}
                controls={false} 
                autoPlay={false}
                loop={false}
              />
              <Ripple
                className='abs-i st15 sr15 ov r sq40 bg0.1 cF dF fxdR aiC jcC'
                onClick={() => {
                  setOpenedVideo(false);
                }}
              >
                <CloseIcon/>
              </Ripple>
            </div>
          ) : null
        }
      </Ripple>
    </div>
  );
});

