import type { FC } from 'react';
import { createStore, createApi } from 'effector';
import { $query, backLocation, pushLocation } from '../../utils/router';
import { TParams } from '../../utils/unparam';
// import { WrapperWithLoading } from '../components/dumb/WrapperWithLoading';
// import { ButtonC } from '../components/dumb/Button/C';



export type TModalComponentProps = {
  modalIndex: number;
  opened: boolean;
  close: () => void;
  closeAll: () => void;
};
export type TModalRenderBox = {
  id: number;
  modalIndex: number;
  opened?: boolean;
  component: FC<TModalComponentProps>;
};

let _modalLastId = 0;

export const $customModalIndex = $query.map((params: TParams) => parseInt(params.modalIndex || 0));
export const $customModals = createStore<TModalRenderBox[]>([]);

export const closeCustomModal = backLocation;
export const { pushCustomModalBase } = createApi($customModals, {
  pushCustomModalBase(customModals, component: FC<TModalComponentProps>) {
    const modalIndexOfPrev = $customModalIndex.getState();
    const modalIndex = 1 + modalIndexOfPrev;

    customModals = customModals.slice(0, modalIndexOfPrev);
    customModals.push({
      id: ++_modalLastId,
      modalIndex,
      component,
    });

    pushLocation({
      query: {
        ...$query.getState(),
        modalIndex,
      },
    });

    return customModals;
  },
});
export const closeAllCustomModals = () => {
  return new Promise((resolve) => {
    function base() {
      if ($customModalIndex.getState()) {
        closeCustomModal();
        setTimeout(base, 100);
      } else {
        resolve(true);
      }
    }
    base();
  });
};

closeAllCustomModals();
