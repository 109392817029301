import React, { useEffect } from "react";
import { SelectProps } from "@mui/material";
import { useUnit } from "effector-react";
import { $regions, regionsAPI } from "../../store/regions";
import { apiRegions } from "../../api/apiRegions";
import { SelectBase } from "../dumb/Select/Base";



export const SelectSmartRegionId = React.memo((props: SelectProps) => {
  const items = useUnit($regions);

  useEffect(() => {
    apiRegions.getList().then((response) => {
      regionsAPI.set(response?.data || []);
    });
  }, []);

  return (
    <SelectBase
      {...props}
      items={items}
    />
  );
});