import React, { useEffect, useState } from 'react';
import { useUnit } from 'effector-react';
import { $interestingBanners, interestingBannersAPI } from '../../../store/interestingBanners';
import { LINK_INTERESTING_BANNERS_ADD } from '../../../constants/links';
import { apiInterestingBanners } from '../../../api/apiInterestingBanners';
import type { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseB } from '../../smart/PageListBaseB';
import { InterestingBannersItem } from './InterestingBannersItem';


const handleRemove = async (params: {
  ids: string[],
}) => {
  await apiInterestingBanners.remove(params)
  interestingBannersAPI.remove(params);
};

export const PageInterestingBannersList = React.memo((props: TRouteProps) => {
  const [loading, setLoading] = useState(false);
  const items = useUnit($interestingBanners);

  useEffect(() => {
    setLoading(true);
    apiInterestingBanners.getList().then((response) => {
      interestingBannersAPI.set(response?.data || []);
      setLoading(false);
    });
  }, [setLoading]);

  return (
    <PageListBaseB
      titleText="Баннеры интересное"
      addLink={LINK_INTERESTING_BANNERS_ADD}
      addText="Добавить баннер"
      items={items}
      Item={InterestingBannersItem}
      onRemove={handleRemove}
      loading={loading}
    />
  );
});