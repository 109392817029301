/**
 * @overview regexpMapperProvider
 * @example
 * const mapper = regexpMapperProvider(/^([^\]*)\/([^\]*)$/g, ['begin', 'end']);
 * var params = {};
 * if (mapper('users/id6574334245', params)) {
 *   console.log(params); // =>
 *   {
 *      begin: 'users',
 *      end: 'id6574334245'
 *   }
 *   return true;
 * }
 * return false;
 */
import isFunction from 'lodash/isFunction';
import {
  mapperProvider,
} from './mapperProvider';

export type TRouteMapper = (text: string, dst?: Record<string, any>) => boolean;


export const regexpMapperProvider = (
  regexp: RegExp,
  keys: string[] | ((values: any[], dst?: Record<string, any>) => void),
): TRouteMapper => {
  const mapper = isFunction(keys) ? keys : mapperProvider(keys);
  return (text: string, dst?: Record<string, any>): boolean => {
    const values = regexp.exec(text || '');
    if (!values) return false;
    dst && mapper(values, dst);
    return true;
  };
};
