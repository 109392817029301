import React from 'react';
import {
  Ripple,
  TRippleProps,
} from '../Ripple';


export const ButtonB = React.memo((props: TRippleProps) => {
  return (
    <Ripple
      {...props}
      className={`dF fxdR aiC jcC h46 pv5 ph15 r5 ov ttU dn bgF.1
        bgF.2:not[.disabled](:h|.active)
        (o50|crA).disabled f16 lh ` + (props.className || '')}
    />
  );
});