/**
 * @overview param
 * - конструктор GET парметров url
 *
 */

import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import {
  withoutEmpty,
} from './withoutEmpty';

const PARAM_WITHOUT_EMPTY_DEFAULT_DEPTH = 10;


export const param = (v: any): string => {
  if (!isObject(v)) return '';
  let s: string[] = [], k: any, l: any;
  s = [];
  function paramBuild(p: string, v: any) {
    v = withoutEmpty(v, PARAM_WITHOUT_EMPTY_DEFAULT_DEPTH);
    v === null || s.push(paramEscape(p) + '=' + paramEscape(
      isObject(v) ? JSON.stringify(v) : ('' + v),
    ));
    return s;
  }
  if (isArray(v)) {
    for (k = 0, l = v.length; k < l; k++) paramBuild('' + k, v[k]);
  } else {
    for (k in v) paramBuild(k, (v as any)[k]); // eslint-disable-line
  }
  return s.sort().join('&');
};

export const paramEscape = (v: string): string => {
  return encodeURIComponent(v)
      .replace(/%20/g, '+')
      .replace(/%22/g, '"')
      .replace(/%3A/g, ':')
      .replace(/%2C/g, ',');
};