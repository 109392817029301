import React, { useMemo, useState } from 'react';
import { backLocationDefer } from '../../../utils/router';
import { WrapperWithLoading } from '../../../components/dumb/WrapperWithLoading';
import { ButtonC } from '../../../components/dumb/Button/C';
import { pushCustomModalA } from './pushCustomModalA';
import { ButtonCancel } from '../../../components/dumb/Button/Cancel';


export type TPushCustomModalAcceptParams = {
  title?: string,
  cancelText?: string,
  acceptText?: string,
  icon?: any,
  content?: any,
  onAccept?: (...args: any[]) => any, 
};

export const pushCustomModalAccept = (params: TPushCustomModalAcceptParams) => {
  const title = params.title || '';
  const cancelText = params.cancelText || 'Отменить';
  const acceptText = params.acceptText || 'Принять';
  const { onAccept, content, icon } = params;

  return pushCustomModalA((props) => {

    const [loading, setLoading] = useState(false);

    const handleAccept = useMemo(() => {
      return onAccept ? (() => {
        (async () => {
          setLoading(true);
          await onAccept();
          backLocationDefer();
        })();
      }) : null;
    }, [setLoading]); 

    return (
      <>
        {icon ? <div className='mb10 dF fxdR aiC jcC'>{icon}</div> : null}
          <div className='f18 tc'>
            {title}
          </div>
          {content ? <div className='f16 mt20'>{content}</div> : null}
          <WrapperWithLoading className='mt20 mh-20' loading={loading}>
            <div className='dF fxdR aiC jcSB ttC ph10>1'>
              <div>
                <ButtonCancel
                  onClick={props.close}
                >
                  {cancelText}
                </ButtonCancel>
              </div>
              {handleAccept ? (
                <div>
                  <ButtonC
                    onClick={handleAccept}
                    component='button'
                    autoFocus
                  >
                    {acceptText}
                  </ButtonC>
                </div>
              ) : null}
            </div>
          </WrapperWithLoading>
      </>
    );
  });
};
