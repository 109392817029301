import {
  createStore,
  createApi,
} from 'effector';


export const cacheProvider = <A extends {
  id: string,
}>(defaultValue?: A[]) => {
  const $items = createStore<A[]>(defaultValue || []);

  const itemsAPI = createApi($items, {
    set: (_, payload: A[]) => {
      return payload;
    },
    add: (store: A[], payload: A) => {
      return [payload, ...store];
    },
    remove: (store: A[], params: {ids: string[]}) => {
      const {
        ids,
      } = params;
      console.log('remove', {
        ids,
        items: store.filter((item) => !ids.includes(item.id)),
      });

      return store.filter((item) => !ids.includes(item.id));
    },
    edit: (store: A[], params: {id: string, data: Partial<A>}) => {
      const {
        id,
        data,
      } = params;

      return store.map((item) => {
        return item.id === id ? {
          ...item,
          ...data,
          id,
        } : item;
      });
    },
  });

  return {
    $items,
    itemsAPI,
  };
};
