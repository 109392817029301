import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trim } from 'lodash';
import { TFile, TCelebrationEmojiState } from '../../types';
import { apiImagesGetUrlById } from '../../api/apiImages';
import { PhotoSelectA } from './PhotoSelectA';
import { HeaderA } from './Header/A';
import { CheckboxA } from '../dumb/Checkbox/A';
import { TextFieldA } from '../dumb/TextField/A';
import { ButtonB } from '../dumb/Button/B';
import { WrapperWithLoading } from '../dumb/WrapperWithLoading';
import { TSetStateArg } from '../../utils/getItemFromStoreById';
import { SelectSmartCelebrationId } from './SelectSmartCelebrationId';

export type TCelebrationEmojiFormProps = {
  className?: string,
  celebrationSelectEnabled?: boolean,
  data: TCelebrationEmojiState,
  onChange: (value: TSetStateArg<TCelebrationEmojiState>) => any,
  onBack?: () => any,
  onSave: () => any,
};


export const CelebrationEmojiForm = React.memo((props: TCelebrationEmojiFormProps) => {
  const {
    onChange,
    onSave,
    data,
  } = props;
  const {
    id,
    text,
    vkEmojiId,
    image,
    celebrationId,
  } = data;


  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  const invalidText = useMemo(() => !trim(text), [text]);

  const invalidVkEmojiId = !vkEmojiId;
  const invalidImage = !image;

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !photos.length;

  const invalidCelebrationId = !celebrationId;

  const disabled = invalidText
    || invalidImage
    || invalidVkEmojiId
    || errorOfPhotos
    || errorOfPhotosExist
    || invalidCelebrationId;
  
  const handleChangeText = useCallback((e: any) => {
    onChange({
      text: e.target.value,
    });
  }, []);

  const handleChangeIsActive = useCallback((e: any) => {
    onChange((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);

  const handleChangePosition = useCallback((e: any) => {
    onChange({
      position: (e.target.value) as any,
    });
  }, []);
  const handleChangeVkEmojiId = useCallback((e: any) => {
    onChange({
      vkEmojiId: (e.target.value) as any,
    });
  }, []);

  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    onChange({
      image: items[0]?.id || '',
    });
  }, []);

  const handleChangeCelebrationId = useCallback((e: any) => {
    onChange({
      celebrationId: e.target.value,
    });
  }, []);


  const handleSubmit = useCallback(() => {
    if (disabled) {
      setClicked(true);
      return;
    }

    setLoading(true);

    onSave();

  }, [disabled, onSave]);

  useEffect(() => {
    if (!image) {
      return;
    }

    const dataUrl = apiImagesGetUrlById(image);
    _setPhotos([
      {
        id: image,
        dataUrl,
        previewUrl: dataUrl,
      },
    ]);
  }, [image]);


  return (
    <div className={props.className || ''}>
      <HeaderA onBack={props.onBack}>
        {id ? 'Редактирование' : 'Создание'} эмодзи: {text}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <CheckboxA
          label="Активный"
          checked={data.isActive}
          onChange={handleChangeIsActive}
        />

        <PhotoSelectA
          className='mt10'
          error={clicked && errorOfPhotosExist}
          limit={1}
          value={photos}
          onChange={setPhotos}
        />

        <TextFieldA
          className='mt10'
          label="Название"
          value={text}
          onChange={handleChangeText}
          error={invalidText && clicked}
        />

        {
          props.celebrationSelectEnabled ? (
            <SelectSmartCelebrationId
              className='mt10'
              label="Празднование"
              value={celebrationId}
              onChange={handleChangeCelebrationId}
              error={invalidCelebrationId && clicked}
            />
          ) : null
        }

        <TextFieldA
          className='mt10'
          label="vkEmojiId"
          value={vkEmojiId}
          onChange={handleChangeVkEmojiId}
          error={invalidVkEmojiId && clicked}
        />

        {
          id ? (
            <TextFieldA
              className='mt10'
              label="Position"
              value={data.position}
              onChange={handleChangePosition}
            />
          ) : null
        }

        <ButtonB
          className='w200 mt30'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});