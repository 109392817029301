import React from 'react';
import { wait } from './wait';


export type TFile = {
  id: string;
  file?: File;
  previewUrl: string;
  dataUrl: string;
  loading?: boolean;
  error?: any;
};
export type TWithFileToolsOptions = {
  accept?: string;
  limit?: number;
};
type TWithFileToolsStateOptions = {
  accept?: string;
  limit: number;
};
export type TSelectFiles = (options?: TWithFileToolsOptions) => Promise<TFile[]>;
export type TWithFileToolsProps = {
  selectFiles: TSelectFiles;
};


let _lastId = 0;

export const withFileTools = <TProps extends Record<string, unknown>>(
  Component: React.ComponentType<TProps & TWithFileToolsProps>,
): React.FC<TProps> => {
  class WrapperComponent extends React.Component {
    constructor(props: TProps & TWithFileToolsProps) {
      super(props);
      const self = this; // eslint-disable-line

      const selectFiles = (async (options?: TWithFileToolsOptions) => {
        const files: File[] = await new Promise((resolve) => {
          options = options || {};
          self.setState({
            options: {
              limit: Math.max(1, options.limit || 0),
            },
            resolve,
          });

          wait(50).then(() => {
            (refOfInput.current as any).click();
          });
        });

        return Promise.all(
          files.map((file: File) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () => {
                const dataUrl = reader.result;
                resolve({
                  id: '' + ++_lastId,
                  file,
                  dataUrl,
                  previewUrl: dataUrl,
                });
              };
              reader.readAsDataURL(file);
            });
          }),
        );
      }) as TSelectFiles;

      const refOfInput = React.createRef<HTMLInputElement>();

      self.state = {
        options: {
          limit: 1,
        },
      };

      self.render = () => {
        const options: TWithFileToolsStateOptions = (self.state as any).options;

        return (
          <>
            <Component {...(self.props as any)} selectFiles={selectFiles} />
            <input
              accept={options.accept}
              className='dN'
              multiple={options.limit > 1}
              ref={refOfInput}
              type='file'
              onChange={handleChange}
            />
          </>
        );
      };

      function handleChange(e: any) {
        const state = self.state as any;
        (state.resolve as any)([].slice.call(e.target.files, 0, state.options.limit));
      }
    }
  }

  return WrapperComponent as any as React.FC<TProps>;
};
