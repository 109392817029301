import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageStoriesList } from './StoriesList';
import { PageStoriesEdit } from './StoriesEdit';


export const PageStories = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageStoriesList}
      PageAdd={PageStoriesEdit}
      PageEdit={PageStoriesEdit}
    />
  );
});