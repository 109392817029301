import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageCelebrationBackgroundsList } from './CelebrationBackgroundsList';
import { PageCelebrationBackgroundsEdit } from './CelebrationBackgroundsEdit';

export const PageCelebrationBackgrounds = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageCelebrationBackgroundsList}
      PageAdd={PageCelebrationBackgroundsEdit}
      PageEdit={PageCelebrationBackgroundsEdit}
    />
  );
});