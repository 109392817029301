/**
 * @overview mapperProvider
 * @example
 * const mapper = mapperProvider([ 'name', 'age']);
 * mapper([ 'Вася', 30 ]) //=> {name: 'Вася', age: 30}
 *
 */

import set from 'lodash/set';

export type TMapper = (values?: any[], dst?: Record<string, any>) => Record<string, any>;

export const mapperProvider = (keys: string[]): TMapper => {
  keys || (keys = []);
  const l = keys.length;
  return (values?: any[], dst?: Record<string, any>): Record<string, any> => {
    dst || (dst = {});
    if (!values) return dst;
    for (let i = 0, v; i < l; i++) {
      (v = values[i]) === undefined || set(dst, keys[i], v);
    }
    return dst;
  };
};
