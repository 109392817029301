import { TFile } from "../types";
import { API_URL_HOSTNAME, requestBase } from "./common";

export const IMAGE_BASE_URL = `${API_URL_HOSTNAME}/images/content/orig/`;


export const apiImagesUploadFile = async (image: TFile): Promise<{
  id: string,
  dataUrl: string,
}> => {
  const {
    file,
  } = image;

  if (!file) {
    throw new Error('File is undefined');
  }

  const formdata = new FormData();

  formdata.append('images[]', file, file.name);

  const body = await requestBase('/images/upload', {
    method: 'POST',
    body: formdata,
  });

  const images = body.data.items;
  const id = images[0].id || image.id;

  return {
    id,
    dataUrl: IMAGE_BASE_URL + id,
  };
};

export const apiGetIdByUrl = (imageUrl: string) => {
  return imageUrl.startsWith(IMAGE_BASE_URL)
  ? imageUrl.substring(IMAGE_BASE_URL.length)
  : imageUrl;
};

export const apiImagesGetUrlById = (id: string) => {
  return id ? (IMAGE_BASE_URL + id) : id;
};

export const apiImagesSerialUpload = async (
  images: TFile[],
  callback: (id: string, image: TFile) => any
) => {
  for (const image of images) {
    const id = image.id;
    try {
      const response = await apiImagesUploadFile(image);
      callback(id, {
        ...image,
        ...response,
        error: null,
        loading: false,
      });
    } catch(error) {
      callback(id, {
        ...image,
        error,
        loading: false,
      });
    }
  }
};
