import React from 'react';
import { Checkbox } from '@mui/material';
import { Link } from '../../../utils/router';
import { ButtonDelete } from '../Button/Delete';
import { ButtonEdit } from '../Button/Edit';


export type TItemAPropsBase<A> = {
  data: A & {
    idOfReplaced?: string,
    nameOfReplaced?: string,
  },
  selected?: boolean,
  onSelect?: (id: string) => any,
  onRemove?: (id: string) => any,
  style?: React.CSSProperties,
  className?: string,
};
export type TItemAProps<A> = TItemAPropsBase<A> & {
  link?: string,
  children?: any,
};

function _ItemA<A extends {id: string}>(props: TItemAProps<A>) {
  const {
    link,
    data,
    onSelect,
    onRemove,
  } = props;
  const {
    id,
  } = data;


  return (
    <div
      className={'h50 lh f14 ph10 pv5 bs b1 bc0.0 bcbF.2 dF fxdR aiC jcS '
        + (props.className || '')}
      style={props.style}
    >
      <div
        className='w(|min)200 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.idOfReplaced || id,
        }}
      />
      {props.children || null}
      {link ? (
        <Link
          component={ButtonEdit}
          href={link + id}
        />
      ) : null}
      {onRemove ? (
        <ButtonDelete
          onClick={() => {
            onRemove(id);
          }}
        />
      ) : null}
      {onSelect ? (
        <Checkbox
          value={props.selected || false}
          onChange={() => {
            onSelect(id);
          }}
        />
      ) : null}
    </div>
  );
};


export const ItemA = React.memo(_ItemA);
