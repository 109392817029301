import React, { useCallback, useEffect } from 'react';
import trim from 'lodash/trim';
import { TCelebrationBackground, TCelebrationBackgroundForm, TCelebrationBackgroundState } from '../../../types';
import { TSetState, useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { backLocation } from '../../../utils/router';
import { TRouteProps } from '../../../utils/routerProvider';
import { apiCelebrationBackgrounds } from '../../../api/apiCelebrationBackgrounds';
import { $celebrationBackgrounds, celebrationBackgroundsAPI } from '../../../store/celebrationBackgrounds';
import { CelebrationBackgroundForm } from '../../smart/CelebrationBackgroundForm';


export const PageCelebrationBackgroundsEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin]: [
    TCelebrationBackgroundState,
    TSetState<TCelebrationBackgroundState>
  ] = useItemFromStoreById($celebrationBackgrounds, id, () => ({
    id,
    name: '',
    image: '',
    previewImage: '',
    celebrationId: '',
    isActive: false,
    position: 0,
  } as any), (v) => ({
    ...v,
    position: '' + v.position,
  }));


  useEffect(() => {
    id && apiCelebrationBackgrounds.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TCelebrationBackground;

      setItemOfOrigin({
        ...data,
        position: '' + data.position,
      } as any);
    });
  }, [id]);

  const handleSubmit = useCallback(() => {
    setItemOfOrigin((dataOfOrigin) => {
      (async () => {
        const id = dataOfOrigin.id;

        let data: TCelebrationBackgroundForm = {
          ...dataOfOrigin,
          name: trim(dataOfOrigin.name),
          celebrationId: trim(dataOfOrigin.celebrationId),
          position: parseInt(dataOfOrigin.position as any),
        };

        const params = data;

        if (id) {
          const {
            error,
          } = await apiCelebrationBackgrounds.change({
            id,
            params,
          });

          if (error) {
            throw error;
          }

          celebrationBackgroundsAPI.edit({
            id,
            data,
          });

        } else {
          const {
            error,
            data: _data,
          } = await apiCelebrationBackgrounds.create({
            params,
          });

          if (error) {
            throw error;
          }

          if (_data) {
            data = {
              ..._data,
            };
          }
  
          celebrationBackgroundsAPI.add(data);
        }

        backLocation();

      })();

      return dataOfOrigin;
    });

  }, []);


  return (
    <CelebrationBackgroundForm
      className='sq'
      data={itemOfOrigin}
      onChange={setItemOfOrigin}
      onSave={handleSubmit}
      celebrationSelectEnabled
    />
  );
});