import React from 'react';
import { SelectBase, TSelectBaseProps } from './Base';


const SelectAItem = React.memo(({
  data,
}: {
  data: any,
}) => {
  return (
    <div className='dF fdR aiC jcFS gap10'>
      {data.id}
    </div>
  );
});


export const SelectA = React.memo((props: TSelectBaseProps) => {
  return (
    <SelectBase
      {...props}
      ItemComponent={SelectAItem}
    />
  );
});