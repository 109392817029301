import React from 'react';
import {
  Ripple,
  TRippleProps,
} from '../Ripple';


export const ButtonIcon = React.memo((props: TRippleProps) => {
  return (
    <Ripple
      {...props}
      className={`sq(|min)40 dF fxdR aiC jcC dn r
        (o50|crA).disabled bgF.1:not[.disabled](:h|.active) `
        + (props.className || '')}
      centerRipple
    />
  );
});