import React from 'react';

import { pushCustomModalBase } from '../store';
import { Ripple } from '../../../components/dumb/Ripple';

export type TPushCustomModalMainProps = {
  close: () => void,
  closeAll: () => void,
  modalIndex: number,
  opened: boolean,
};


export const pushCustomModalMain = (Component: React.ElementType<TPushCustomModalMainProps>) => {
  return pushCustomModalBase((props: TPushCustomModalMainProps) => {
    return (
      <div
        className={'dF fxdR aiC jcC lh110% ov fixed s bg0.6 z10001 dn o o100.opened' + (props.opened ? ' opened' : '')}
        style={{
          zIndex: 97 + props.modalIndex,
        }}
      >
        <Ripple className='abs s' onClick={props.close} />
        <Component {...props} />
      </div>
    );
  });
};

/*

Если понадобится сделать модалку с другим каркасом,
то можно будет добавлять новые функции

export const pushCustomModalB = (Component: FC<any>) => {
  pushCustomModalBase((props) => {
    return (
      <div
        className={'AppCustomModalB' + (props.opened ? ' AppCustomModalB_opened' : '')}
        style={{
          zIndex: 10001 + props.modalIndex,
        }}
      >
        <div
          className='AppCustomModalB__Background'
          onClick={goBack}
        />
        <div className='AppCustomModalB__Box'>
          <Component/>
        </div>
      </div>
    );
  });
};
*/
