import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TStoryPage } from '../../../../types';
import { Ratio } from '../../../dumb/Ratio';
import { Ripple } from '../../../dumb/Ripple';
import { WrapperWithLoading } from '../../../dumb/WrapperWithLoading';
import { apiStoryPages } from '../../../../api/apiStoryPages';
import { SelectStoryPagesItem } from './SelectStoryPagesItem';
import { StoryPageForm } from './StoryPageForm';
import { BUTTON_TYPE_DEFAULT, DEFAULT_SECONDS_DURATION } from './constants';
import { withState } from '../../../../utils/withState';


export type TSelectStoryPagesProps = {
  className?: string,
  storyId?: string,
};

export type TSelectStoryPagesState = {
  pages: TStoryPage[],
  storyPageId: string,
  storyPageData: TStoryPage,
  loading: boolean,
  opened: boolean,
};


function storyPageDataInitial() {
  return {
    title: '',
    subTitle: '',
    description: '',
    buttonText: '',
    buttonUrl: '',
    buttonType: BUTTON_TYPE_DEFAULT,
    isActive: true,
    isInfinityDuration: false,
    image: '',
    storyId: '',
    position: 0,
    secondsDuration: DEFAULT_SECONDS_DURATION,
    videoUrl: '',
    pageType: '',
  };
}


export const SelectStoryPages = withState<TSelectStoryPagesProps, TSelectStoryPagesState>((setState, self) => {
  self.state = {
    pages: [],
    storyPageId: '',
    storyPageData: storyPageDataInitial(),
    loading: true,
    opened: false,
  };
  
  function handleAddStoryPage() {
    setState({
      opened: true,
      storyPageId: '',
      storyPageData: storyPageDataInitial(),
    });
  }

  function handleSelect(id: string) {
    setState({
      opened: true,
      storyPageId: id,
      storyPageData: self.state.pages.find((v) => v.id === id),
    });
  }

  async function handleRemove(params: {
    ids: string[],
  }) {
    setState({
      loading: true,
    });
    await apiStoryPages.remove(params);

    const {
      ids,
    } = params;
    setState({
      loading: false,
      pages: self.state.pages
        .filter((page) => !ids.includes(page.id || '')),
    });
  }

  function handleChangeStoryPage(storyPageData: Partial<TStoryPage> | ((v: TStoryPage) => Partial<TStoryPage>)) {
    const prev = self.state.storyPageData;
    
    setState({
      storyPageData: {
        ...prev,
        ...(typeof storyPageData === 'function' ? storyPageData(prev) : storyPageData),
      },
    });
  }

  function handleBack() {
    setState({
      opened: false,
      storyPageId: '',
      storyPageData: storyPageDataInitial(),
    });
  }

  async function handleSave() {
    const {
      state,
      props,
    } = self;
    const {
      storyPageId,
      storyPageData,
      pages,
    } = state;


    const params = {
      ...storyPageData,
      position: parseInt(storyPageData.position as any || '0'),
      storyId: props.storyId || '',
    };

    /*
    console.log('params', {
      storyPageId,
      params,
    });
    */

    if (storyPageId) {
      apiStoryPages.change({
        id: storyPageId,
        params,
      });

      setState({
        opened: false,
        storyPageId: '',
        storyPageData: storyPageDataInitial(),
        pages: pages.map((v) => {
          return v.id === storyPageId ? {
            ...storyPageData,
            id: storyPageId,
          } : v;
        }),
      });

    } else {
      const response = await apiStoryPages.create({
        params,
      });
      const data = response?.data;

      data && setState({
        opened: false,
        storyPageId: '',
        storyPageData: storyPageDataInitial(),
        pages: [...pages, data],
      });
    }

  }

  const {
    localEffect,
    localMemo,
  } = self;


  return (props, state) => {
    const {
      storyId,
    } = props;
    const {
      pages,
      storyPageData,
    } = state;

    localEffect(async () => {
      const response = await apiStoryPages.getList({
        query: {
          storyId,
        },
      });
      setState({
        pages: response?.data || [],
        loading: false,
      });
    }, [storyId]);

    const items = localMemo(() => {
      const items = [...pages];
      items.sort((a, b) => a.position - b.position);
      return items;
    }, [pages]);

    return (
      <WrapperWithLoading
        className={'SelectStoryPages w ' + (props.className || '')}
        loading={state.loading}
      >
        <div className='rlv'>
          <div className='ov ovxA'>
            <div className='dF aiC jcFS mh-4 (lt|p4)>1'>
              <div>
                <Ripple
                  className='w(|min)360 dn bg0 bg1:h ov'
                  onClick={handleAddStoryPage}
                >
                  <Ratio
                    className='dF aiC jcC'
                    width={360}
                    height={764}
                  >
                    
                    <AddIcon className='f50-i'/>
                  </Ratio>
                </Ripple>
              </div>
              {items.map((page) => {
                return (
                  <SelectStoryPagesItem
                    key={page.id}
                    data={page}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                  />
                );
              })}
            </div>
          </div>
          {
            state.opened ? (
              <StoryPageForm
                className='abs s10 r10 bg2 ov ovyA z2'
                data={storyPageData}
                onChange={handleChangeStoryPage}
                onBack={handleBack}
                onSave={handleSave}
              />
            ) : null
          }
        </div>
      </WrapperWithLoading>
    );
  };
});
