import dayjs, { Dayjs } from "dayjs";

export const normalizeDate = (date: Dayjs | null, end?: boolean) => {
  if (!date) {
    return null;
  }
  const d = date.toDate();

  return dayjs(`${d.getFullYear()}-${('' + (1 + d.getMonth())).padStart(2, '0')}-${('' + d.getDate()).padStart(2, '0')}T${end ? `23:59:59` : `00:00:00`}+03:00`);
};
