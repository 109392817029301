import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trim } from 'lodash';
import { TFile, TStoryPage } from '../../../../types';
import { apiImagesGetUrlById } from '../../../../api/apiImages';
import { HeaderA } from '../../../smart/Header/A';
import { CheckboxA } from '../../../dumb/Checkbox/A';
import { TextFieldA } from '../../../dumb/TextField/A';
import { PhotoSelectA } from '../../../smart/PhotoSelectA';
import { ButtonB } from '../../../dumb/Button/B';
import { WrapperWithLoading } from '../../../dumb/WrapperWithLoading';
import { SelectBase } from '../../../dumb/Select/Base';
import {
  BUTTON_TYPES,
  BUTTON_TYPE_NEXT_PAGE,
  BUTTON_TYPE_REDIRECT,
  SECONDS_DURATION_ITEMS,
  STORY_PAGE_TYPES,
  STORY_PAGE_TYPE_IMAGE,
  STORY_PAGE_TYPE_VIDEO
} from './constants';


export type TStoryPageFormProps = {
  className?: string,
  data: TStoryPage,
  onChange: (value: Partial<TStoryPage> | ((v: TStoryPage) => Partial<TStoryPage>)) => any,
  onBack: () => any,
  onSave: () => any,
};


export const StoryPageForm = React.memo((props: TStoryPageFormProps) => {
  const {
    onChange,
    onSave,
    data,
  } = props;
  const {
    id,
    title,
    isInfinityDuration,
    buttonType,
    buttonUrl,
    buttonText,
    videoUrl,
  } = data;

  const pageType = data.pageType || STORY_PAGE_TYPE_IMAGE;

  const isVideoPageStory = pageType === STORY_PAGE_TYPE_VIDEO;

  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  const invalidTitle = useMemo(() => !trim(title), [title]);

  const invalidVideoUrlBase = useMemo(() => !trim(videoUrl), [videoUrl]);
  const invalidVideoUrl = isVideoPageStory && invalidVideoUrlBase;

  const invalidButtonUrlBase = useMemo(() => !trim(buttonUrl), [buttonUrl]);
  const invalidButtonUrl = buttonType === BUTTON_TYPE_REDIRECT && invalidButtonUrlBase;
  
  const invalidButtonTextBase = useMemo(() => !trim(buttonText), [buttonText]);
  const invalidButtonText = (buttonType === BUTTON_TYPE_REDIRECT || buttonType === BUTTON_TYPE_NEXT_PAGE)
    && invalidButtonTextBase;


  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );

  const imageId = data.image;
  const errorOfPhotosExist = !imageId;


  const disabled = invalidTitle
    || invalidButtonUrl
    || invalidButtonText
    || invalidVideoUrl
    || errorOfPhotos
    || errorOfPhotosExist;

  
  const handleChangePageType = useCallback((e: any) => {
    onChange((v) => ({
      pageType: e.target.value,
    }));
  }, [onChange]);

  const handleChangeVideoUrl = useCallback((e: any) => {
    onChange((v) => ({
      videoUrl: e.target.value,
    }));
  }, [onChange]);

  const handleChangeIsActive = useCallback((e: any) => {
    onChange((v) => ({
      isActive: !v.isActive,
    }));
  }, [onChange]);

  const handleChangeIsInfinityDuration = useCallback((e: any) => {
    onChange((v) => ({
      isInfinityDuration: !v.isInfinityDuration,
    }));
  }, [onChange]);

  const handleChangeTitle = useCallback((e: any) => {
    onChange({
      title: e.target.value,
    });
  }, [onChange]);

  const handleChangeSubTitle = useCallback((e: any) => {
    onChange({
      subTitle: e.target.value,
    });
  }, [onChange]);

  const handleChangeDescription = useCallback((e: any) => {
    onChange({
      description: e.target.value,
    });
  }, [onChange]);

  const handleChangePosition = useCallback((e: any) => {
    onChange({
      position: e.target.value.replace(/[^0-9]/gim, ''),
    });
  }, [onChange]);

  const handleChangeSecondsDuration = useCallback((e: any) => {
    onChange({
      secondsDuration: e.target.value,
    });
  }, [onChange]);

  const handleChangeButtonText = useCallback((e: any) => {
    onChange({
      buttonText: e.target.value,
    });
  }, [onChange]);

  const handleChangeButtonUrl = useCallback((e: any) => {
    onChange({
      buttonUrl: e.target.value,
    });
  }, [onChange]);

  const handleChangeButtonType = useCallback((e: any) => {
    onChange({
      buttonType: e.target.value,
    });
  }, [onChange]);

  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    onChange({
      image: items[0]?.id || '',
    });
  }, [onChange, _setPhotos]);

  const handleSubmit = useCallback(() => {
    if (disabled) {
      setClicked(true);
      return;
    }

    setLoading(true);

    onSave();

  }, [disabled, setClicked, setLoading, onSave]);


  useEffect(() => {
    if (!imageId) {
      return;
    }

    const dataUrl = apiImagesGetUrlById(imageId);
    _setPhotos([
      {
        id: imageId,
        dataUrl,
        previewUrl: dataUrl,
      },
    ]);
  }, [_setPhotos, imageId]);


  return (
    <WrapperWithLoading
      className={props.className || ''}
      loading={loading}
    >
      <HeaderA onBack={props.onBack}>
        {id ? 'Редактирование' : 'Создание'} страницы сторис: {title}
      </HeaderA>
      <div className='p15 pt'>
        <CheckboxA
          label="Активный"
          checked={data.isActive}
          onChange={handleChangeIsActive}
        />

        <SelectBase
          className='mt15'
          label="pageType"
          items={STORY_PAGE_TYPES}
          value={pageType}
          onChange={handleChangePageType}
        />

        <div className="mt15">
          Изображение 860 x 1590
        </div>
        <PhotoSelectA
          className='mt10'
          error={clicked && errorOfPhotosExist}
          limit={1}
          value={photos}
          onChange={setPhotos}
        />
        {
          isVideoPageStory ? (
            <TextFieldA
              className='mt15'
              label="Video URL"
              value={videoUrl}
              onChange={handleChangeVideoUrl}
              error={invalidVideoUrl && clicked}
            />
          ) : null
        }
        <TextFieldA
          className='mt15'
          label="Заголовок"
          value={title}
          onChange={handleChangeTitle}
          error={invalidTitle && clicked}
        />
        <TextFieldA
          className='mt15'
          label="Подзаголовок"
          value={data.subTitle}
          onChange={handleChangeSubTitle}
        />
        {isVideoPageStory ? null : (
          <TextFieldA
            className='mt15'
            label="Описание"
            value={data.description}
            onChange={handleChangeDescription}
          />
        )}
        <TextFieldA
          className='mt15'
          label="Текст кнопки"
          value={buttonText}
          onChange={handleChangeButtonText}
          error={invalidButtonText && clicked}
        />
        <TextFieldA
          className='mt15'
          label="URL кнопки"
          value={buttonUrl}
          onChange={handleChangeButtonUrl}
          error={invalidButtonUrl && clicked}
        />

        <SelectBase
          className='mt15'
          label="buttonType"
          items={BUTTON_TYPES}
          value={buttonType}
          onChange={handleChangeButtonType}
        />

        {
          id ? (
            <TextFieldA
              className='mt15'
              label="Position"
              value={data.position}
              onChange={handleChangePosition}
            />
          ) : null
        }

        <CheckboxA
          className='mt15'
          label="Бесконечная длительность"
          checked={isInfinityDuration}
          onChange={handleChangeIsInfinityDuration}
        />

        {isInfinityDuration ? null : (
          <SelectBase
            className='mt15'
            label="Длительность, сек."
            items={SECONDS_DURATION_ITEMS}
            value={data.secondsDuration}
            onChange={handleChangeSecondsDuration}
          />
        )}
        
        <ButtonB
          className='w200 mt30'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </div>
    </WrapperWithLoading>
  );
});