import React from 'react';
import { LINK_INTERESTING_BANNERS_EDIT } from '../../../constants/links';
import { TInterestingBanner } from '../../../types';
import { ItemA, TItemAPropsBase } from '../../dumb/Item/A';


export const InterestingBannersItem = React.memo((props: TItemAPropsBase<TInterestingBanner>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_INTERESTING_BANNERS_EDIT}
    >
      <div
        className='w200 ov hmax ov ph5 lh cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.title,
        }}
      />
      <div className='w150 hmax ov ph5 lh'>
        <div
          className='f11 mb5'
        >Текст кнопки</div>
        <div>
          {data.buttonText}
        </div>
      </div>
      <div className='w80 hmax ov ph5 lh'>
        <div
          className='f11 mb5'
        >Позиция</div>
        <div>
          {data.position}
        </div>
      </div>
      <div className='fx1 hmax wmin0 ov ph5 lh'>
        <div
          className='f11 mb5'
        >URL</div>
        <div>
          {data.url}
        </div>
      </div>
    </ItemA>
  );
});
