import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export const Loading = React.memo((props: {
  className?: string,
}) => {
  return (
    <div
      {...props}
      className={'abs s dF fxdR aiC jcC ' + (props.className || '')}
    >
      <CircularProgress
        className="mhA dB*2 cCT*2 Loading__Inner"
      />
    </div>
  );
});