import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';

export type TRippleProps = {
  disabled?: boolean,
  children?: any,
  className?: string,
  component?: any,
  href?: string,
  onClick?: (e: any) => any,
  centerRipple?: boolean, 
  autoFocus?: boolean,
  type?: string,
};

export const Ripple = React.memo(({
  disabled,
  children,
  className,
  component,
  ...props
}: TRippleProps) => {
  return (
    <div
      className={'rlv '
        + (disabled ? 'disabled ' : '')
        + (className || '')}
    >
      {children}
      {disabled ? null : (<ButtonBase
        {...props}
        component={component || 'a'}
        className="dB*2 abs*2 s crP*2 w"
        draggable={false}
      />)}
    </div>
  );
});