import React from 'react';
import {
  Ripple,
  TRippleProps,
} from '../Ripple';


export const ButtonA = React.memo((props: TRippleProps) => {
  return (
    <Ripple
      {...props}
      className={`dF fxdR aiC jcS h46 ph15 ov dn
        bgF.1:not[.disabled](:h|.active)
        (o50|crA).disabled lh f16 ` + (props.className || '')}
    />
  );
});