import React from 'react';
import { TPushCustomModalMainProps, pushCustomModalMain } from './pushCustomModalMain';


export const pushCustomModalA = (Component: React.ElementType<TPushCustomModalMainProps>) => {
  return pushCustomModalMain((props) => {
    return (
      <div
        className={'rvl w400 bg2 r5 ov p20 hmax100%-50 ov ovyA hmin50 dn xY-100 xY0.opened'
          + (props.opened ? ' opened' : '')}
      >
        <Component {...props} />
      </div>
    );
  });
};
