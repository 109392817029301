import React from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  TRippleProps,
} from '../Ripple';
import { ButtonIcon } from './Icon';


export const ButtonReplay = React.memo((props: TRippleProps) => {
  return (
    <ButtonIcon
      {...props}
    >
      <ReplayIcon/>
    </ButtonIcon>
  );
});