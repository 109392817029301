import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { TCelebrationEmoji } from '../../../../../types';
import { Ratio } from '../../../../dumb/Ratio';
import { Ripple } from '../../../../dumb/Ripple';
import { apiImagesGetUrlById } from '../../../../../api/apiImages';
import { pushCustomModalRemove } from '../../../../../services/customModals/modals/pushCustomModalRemove';

export type TSelectEmojiItemProps = {
  data: TCelebrationEmoji,
  onSelect: (id: string) => any,
  onRemove: (params: {
    ids: string[],
  }) => any,
};

export const SelectEmojiItem = React.memo((props: TSelectEmojiItemProps) => {
  const {
    data,
  } = props;
  const id = data.id || '';

  return (
    <div>
      <Ripple
        className='w(|min)115 r12 ov bgF.1 bgF.2:h dn'
        onClick={() => {
          props.onSelect(id);
        }}
      >
        <Ratio
          className='dF aiC jcC bxz aiC jcC ph10'
          width={115}
          height={58}
        >
          <img
            className='dB w20'
            src={apiImagesGetUrlById(data.image)}
          />
          <div className='ml8 f11 fsN fw4 lh14 cF usN break'>
            {data.text}
          </div>
        </Ratio>
        <Ripple
          className='abs st sr sq22 dF fxdR aiC jcC ov r bg4 bg6:h dn cF z1'
          onClick={() => {
            pushCustomModalRemove({
              onAccept: () => {
                props.onRemove({
                  ids: [id],
                });
              },
            });
          }}
        >
          <DeleteOutlineIcon className='dB sq18-i'/>
        </Ripple>
      </Ripple>
      <div className='mt5 f12 h18'>
        vkEmojiId: {data.vkEmojiId}
      </div>
    </div>
  );
});

