import { param } from './param';

const REGEXP_VK_VIDEO = /^https:\/\/vk\.com\/([A-Za-z_]+)(-?\d+)_(\d+)/im;

export type TVKVideoUrlParseResult = {
  type: string;
  oid: string;
  id: string;
};

/*
<iframe src="https://vk.com/video_ext.php?oid=-218905599&id=456239296&hd=2&autoplay=1" width="363" height="714" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
*/

export const VKVideoUrlParse = (url: string): TVKVideoUrlParseResult | undefined => {
  const match = REGEXP_VK_VIDEO.exec(url);
  if (match) {
    return {
      type: match[1],
      oid: match[2],
      id: match[3],
    };
  }
};

export const getVKVideoUrl = (url: string, params?: Record<string, any>): string => {
  const data = VKVideoUrlParse(url);
  let vkVideoUrl = '';

  if (data) {
    vkVideoUrl = `https://vk.com/video_ext.php?oid=${data.oid}&id=${data.id}&hd=2`;
    const q = param(params);
    q && (vkVideoUrl += '&' + q);
  }

  return vkVideoUrl;
};
