import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  TRippleProps,
} from '../Ripple';
import { ButtonIcon } from './Icon';


export const ButtonView = React.memo((props: TRippleProps) => {
  return (
    <ButtonIcon
      {...props}
    >
      <VisibilityIcon/>
    </ButtonIcon>
  );
});