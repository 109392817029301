import React from 'react';
import { PageListBaseSortButton } from '../../../smart/PageListBaseSortButton';

export const SortHeader = React.memo((props: {
  value: string,
  onChange: (field: string) => void,
}) => {
  const {
    value,
    onChange,
  } = props;

  return (
    <>
      <div className='w200'>
        <PageListBaseSortButton
          field='title'
          value={value}
          onChange={onChange}
        />
      </div>
      <div className='w80'>
        <PageListBaseSortButton
          field='position'
          value={value}
          onChange={onChange}
        />
      </div>
      <div className='fx1 wmin0'>
        <PageListBaseSortButton
          field='regionName'
          value={value}
          onChange={onChange}
        />
      </div>
      <div className='w(|min)100'>
        <PageListBaseSortButton
          field='isActive'
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
});