import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trim } from 'lodash';
import { TFile, TCelebrationBackgroundState } from '../../types';
import { apiImagesGetUrlById } from '../../api/apiImages';
import { PhotoSelectA } from './PhotoSelectA';
import { HeaderA } from './Header/A';
import { CheckboxA } from '../dumb/Checkbox/A';
import { TextFieldA } from '../dumb/TextField/A';
import { ButtonB } from '../dumb/Button/B';
import { WrapperWithLoading } from '../dumb/WrapperWithLoading';
import { TSetStateArg } from '../../utils/getItemFromStoreById';
import { SelectSmartCelebrationId } from './SelectSmartCelebrationId';

export type TCelebrationBackgroundFormProps = {
  className?: string,
  celebrationSelectEnabled?: boolean,
  data: TCelebrationBackgroundState,
  onChange: (value: TSetStateArg<TCelebrationBackgroundState>) => any,
  onBack?: () => any,
  onSave: () => any,
};


export const CelebrationBackgroundForm = React.memo((props: TCelebrationBackgroundFormProps) => {
  const {
    onChange,
    onSave,
    data,
  } = props;
  const {
    id,
    name,
    image,
    previewImage,
    celebrationId,
  } = data;


  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);
  const [previewPhotos, _setPreviewPhotos] = useState<TFile[]>([]);

  const invalidName = useMemo(() => !trim(name), [name]);

  const invalidImage = !image;
  const invalidPreviewImage = !previewImage;

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !photos.length;

  const errorOfPreviewPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPreviewPhotosExist = !previewPhotos.length;

  const invalidCelebrationId = !celebrationId;

  const disabled = invalidName
    || invalidImage
    || invalidPreviewImage
    || errorOfPreviewPhotos
    || errorOfPreviewPhotosExist
    || errorOfPhotos
    || errorOfPhotosExist
    || invalidCelebrationId;
  
  const handleChangeName = useCallback((e: any) => {
    onChange({
      name: e.target.value,
    });
  }, []);

  const handleChangeIsActive = useCallback((e: any) => {
    onChange((v) => {
      return {
        ...v,
        isActive: !v.isActive,
      };
    });
  }, []);

  const handleChangePosition = useCallback((e: any) => {
    onChange({
      position: (e.target.value) as any,
    });
  }, []);

  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    onChange({
      image: items[0]?.id || '',
    });
  }, []);

  const setPreviewPhotos = useCallback((items: TFile[]) => {
    _setPreviewPhotos(items);
    onChange({
      previewImage: items[0]?.id || '',
    });
  }, []);

  const handleChangeCelebrationId = useCallback((e: any) => {
    onChange({
      celebrationId: e.target.value,
    });
  }, []);


  const handleSubmit = useCallback(() => {
    if (disabled) {
      setClicked(true);
      return;
    }

    setLoading(true);

    onSave();

  }, [disabled, onSave]);


  useEffect(() => {
    if (!image) {
      return;
    }

    const dataUrl = apiImagesGetUrlById(image);
    _setPhotos([
      {
        id: image,
        dataUrl,
        previewUrl: dataUrl,
      },
    ]);
  }, [image]);


  useEffect(() => {
    if (!previewImage) {
      return;
    }

    const dataUrl = apiImagesGetUrlById(previewImage);
    _setPreviewPhotos([
      {
        id: previewImage,
        dataUrl,
        previewUrl: dataUrl,
      },
    ]);
  }, [previewImage]);


  return (
    <div className={props.className || ''}>
      <HeaderA onBack={props.onBack}>
        {id ? 'Редактирование' : 'Создание'} обложки: {name}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <CheckboxA
          label="Активный"
          checked={data.isActive}
          onChange={handleChangeIsActive}
        />

        <div className='mt10'>
          Изображение
        </div>
        <PhotoSelectA
          error={clicked && errorOfPhotosExist}
          limit={1}
          value={photos}
          onChange={setPhotos}
        />

        <div className='mt20'>
          Превью
        </div>
        <PhotoSelectA
          error={clicked && errorOfPreviewPhotosExist}
          limit={1}
          value={previewPhotos}
          onChange={setPreviewPhotos}
        />

        <TextFieldA
          className='mt10'
          label="Название"
          value={name}
          onChange={handleChangeName}
          error={invalidName && clicked}
        />

        {
          props.celebrationSelectEnabled ? (
            <SelectSmartCelebrationId
              className='mt10'
              label="Празднование"
              value={celebrationId}
              onChange={handleChangeCelebrationId}
              error={invalidCelebrationId && clicked}
            />
          ) : null
        }

        {
          id ? (
            <TextFieldA
              className='mt10'
              label="Position"
              value={data.position}
              onChange={handleChangePosition}
            />
          ) : null
        }

        <ButtonB
          className='w200 mt30'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});