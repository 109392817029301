import React from 'react';
import { LINK_PLACES_EDIT } from '../../../../constants/links';
import { TPlace } from '../../../../types';
import { ItemA, TItemAPropsBase } from '../../../dumb/Item/A';


export const PlacesItem = React.memo((props: TItemAPropsBase<TPlace>) => {
  const {
    data,
  } = props;

  return (
    <ItemA
      {...props}
      link={LINK_PLACES_EDIT}
    >
      <div
        className='w300 ov tov wsNW ph5 cF00>b'
        dangerouslySetInnerHTML={{
          __html: data.nameOfReplaced || data.name,
        }}
      />
      <div className='fx1 wmin0 ov tov wsNW ph5'>
        {(data as any).regionName}
      </div>
      <div className='w(|min)100 ov tov wsNW ph5'>
        {data.isActive ? 'Активный' : 'Неактивный'}
      </div>
    </ItemA>
  );
});

