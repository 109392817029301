import keys from 'lodash/keys';
import {
  tryJsonParse,
} from './tryJsonParse';
import {
  createStore,
  createApi,
  StoreWritable,
} from 'effector';

export type TCookieStorage = StoreWritable<any> & {
  set: (key: string, value: any) => TCookieStorage,
  get: (key: string) => any,
  remove: (key: string) => TCookieStorage,
  getKeys: () => string[],
  clear: () => TCookieStorage,
};


const expires = 400 * 86400000;

function storageInit(cookie: string): any {
  const w = cookie ? cookie.split('; ') : [];
  const l = w.length;
  const output: any = {};
  let i = 0, parts, k; // eslint-disable-line
  for (; i < l; i++) {
    k = decodeURIComponent((parts = w[i].split('='))[0]);
    output[k] = tryJsonParse(decodeURIComponent(parts[1]));
  }
  return output;
}

export const cookieStorageProvider = (ctx: Window): TCookieStorage => {
  const $instance = createStore<any>({} as any);
  const {
    emit,
  } = createApi($instance, {
    emit: (_: any, payload: any) => payload,
  });

  const doc = ctx.document;
  const cache = storageInit(doc.cookie);
  function __set(key: string, value: string) {
    const date = new Date();
    date.setMilliseconds(date.getMilliseconds() + expires);
    doc.cookie = encodeURIComponent(key) + '='
      + encodeURIComponent(value) + '; expires=' + date.toUTCString();
  }
  function set(key: string, value: any) {
    emit({key, value} as any);
    return $instance;
  }
  $instance.watch(({key, value}: {
    key: string,
    value: any
  }) => {
    if (value === cache[key]) return;
    if (value === null || value === undefined) {
      delete cache[key];
      __set(key, '');
    } else {
      __set(key, JSON.stringify(cache[key] = value));
    }
  });
  ($instance as any).set = set;
  ($instance as any).get = (key: string) => cache[key];
  ($instance as any).remove = (key: string) => set(key, null);
  ($instance as any).getKeys = () => keys(cache);
  ($instance as any).clear = () => {
    let key;
    for (key in cache) emit({ // eslint-disable-line
      key,
      value: cache[key],
    } as any);
    return $instance;
  };
  return $instance as any;
};