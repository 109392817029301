import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  TRippleProps,
} from '../Ripple';
import { ButtonIcon } from './Icon';


export const ButtonDelete = React.memo((props: TRippleProps) => {
  return (
    <ButtonIcon
      {...props}
    >
      <DeleteIcon/>
    </ButtonIcon>
  );
});