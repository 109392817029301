import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { TCelebrationBackground, TCelebrationBackgroundState } from '../../../../../types';
import { Ratio } from '../../../../dumb/Ratio';
import { Ripple } from '../../../../dumb/Ripple';
import { WrapperWithLoading } from '../../../../dumb/WrapperWithLoading';
import { apiCelebrationBackgrounds } from '../../../../../api/apiCelebrationBackgrounds';
import { withState } from '../../../../../utils/withState';
import { SelectBackgroundItem } from './SelectBackgroundItem';
import { CelebrationBackgroundForm } from '../../../../smart/CelebrationBackgroundForm';


export type TSelectBackgroundPagesProps = {
  className?: string,
  celebrationId?: string,
};

export type TSelectBackgroundPagesState = {
  pages: TCelebrationBackground[],
  backgroundId: string,
  backgroundData: TCelebrationBackgroundState,
  loading: boolean,
  opened: boolean,
};





export const SelectBackgrounds = withState<TSelectBackgroundPagesProps, TSelectBackgroundPagesState>((setState, self) => {
  function backgroundDataInitial() {
    return {
      id: '',
      isActive: true,
      celebrationId: self.props.celebrationId || '',
      position: '',
      name: '',
      image: '',
      previewImage: '',
    };
  }
  
  
  self.state = {
    pages: [],
    backgroundId: '',
    backgroundData: backgroundDataInitial(),
    loading: true,
    opened: false,
  };
  
  function handleAddBackground() {
    setState({
      opened: true,
      backgroundId: '',
      backgroundData: backgroundDataInitial(),
    });
  }

  function handleSelect(id: string) {
    const data = self.state.pages.find((v) => v.id === id) || backgroundDataInitial();

    setState({
      opened: true,
      backgroundId: id,
      backgroundData: {
        ...data,
        position: '' + (data.position || 0),
        // imageUrl: apiGetIdByUrl(data.imageUrl),
        // previewImageUrl: apiGetIdByUrl(data.previewImageUrl),
      },
    });
  }

  async function handleRemove(params: {
    ids: string[],
  }) {
    setState({
      loading: true,
    });
    await apiCelebrationBackgrounds.remove(params);

    const {
      ids,
    } = params;
    setState({
      loading: false,
      pages: self.state.pages
        .filter((page) => !ids.includes(page.id || '')),
    });
  }

  function handleChangeBackground(backgroundData: Partial<TCelebrationBackgroundState> | ((v: TCelebrationBackgroundState) => Partial<TCelebrationBackgroundState>)) {
    const prev = self.state.backgroundData;
    
    setState({
      backgroundData: {
        ...prev,
        ...(typeof backgroundData === 'function' ? backgroundData(prev) : backgroundData),
      },
    });
  }

  function handleBack() {
    setState({
      opened: false,
      backgroundId: '',
      backgroundData: backgroundDataInitial(),
    });
  }

  async function handleSave() {
    const {
      state,
      props,
    } = self;
    const {
      backgroundId,
      backgroundData,
      pages,
    } = state;


    const params = {
      ...backgroundData,
      position: parseInt(backgroundData.position as any || '0'),
      celebrationId: props.celebrationId || '',
      // imageUrl: apiImagesGetUrlById(backgroundData.imageUrl),
      // previewImageUrl: apiImagesGetUrlById(backgroundData.previewImageUrl),
    };

    /*
    console.log('params', {
      backgroundPageId,
      params,
    });
    */

    if (backgroundId) {
      apiCelebrationBackgrounds.change({
        id: backgroundId,
        params,
      });

      setState({
        opened: false,
        backgroundId: '',
        backgroundData: backgroundDataInitial(),
        pages: pages.map((v) => {
          return v.id === backgroundId ? {
            ...params,
            id: backgroundId,
          } : v;
        }),
      });

    } else {
      const response = await apiCelebrationBackgrounds.create({
        params,
      });
      const data = response?.data;

      data && setState({
        opened: false,
        backgroundId: '',
        backgroundData: backgroundDataInitial(),
        pages: [...pages, data],
      });
    }

  }

  const {
    localEffect,
    localMemo,
  } = self;


  return (props, state) => {
    const {
      celebrationId,
    } = props;
    const {
      pages,
      backgroundData,
    } = state;

    localEffect(async () => {
      const response = await apiCelebrationBackgrounds.getList({
        query: {
          celebrationId: celebrationId,
        },
      });
      setState({
        pages: response?.data || [],
        loading: false,
      });
    }, [celebrationId]);

    const items = localMemo(() => {
      const items = [...pages];
      items.sort((a, b) => a.position - b.position);
      return items;
    }, [pages]);


    return (
      <WrapperWithLoading
        className={'SelectBackgroundPages w ' + (props.className || '')}
        loading={state.loading}
      >
        <div className='rlv'>
          <div className='ov ovxA'>
            <div className='dF aiC jcFS mh-4 (lt|p4)>1'>
              <div>
                <Ripple
                  className='w(|min)115 r12 ov bgF.1 bgF.2:h dn'
                  onClick={handleAddBackground}
                >
                  <Ratio
                    className='dF aiC jcC bxz aiC jcC ph10'
                    width={115}
                    height={58}
                  >
                    <AddIcon className='f50-i'/>
                  </Ratio>
                </Ripple>
                <div className='mt5 h18'/>
              </div>
              {items.map((page) => {
                return (
                  <SelectBackgroundItem
                    key={page.id}
                    data={page}
                    onSelect={handleSelect}
                    onRemove={handleRemove}
                  />
                );
              })}
            </div>
          </div>
          {
            state.opened ? (
              <CelebrationBackgroundForm
                className='abs sh-16 sb-16 r10 bg1 ov ovyA z2 hmin600'
                data={backgroundData}
                onChange={handleChangeBackground}
                onBack={handleBack}
                onSave={handleSave}
              />
            ) : null
          }
        </div>
      </WrapperWithLoading>
    );
  };
});
