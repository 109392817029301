import forEach from 'lodash/forEach';
import {
  createStore,
  createApi,
  StoreWritable,
} from 'effector';
import {
  tryJsonParse,
} from './tryJsonParse';
import {
  attachEvent,
} from './attachEvent';

export type TLocalStorage = StoreWritable<any> & {
  set: (key: string, value: any) => TLocalStorage,
  get: (key: string) => any,
  remove: (key: string) => TLocalStorage,
  getKeys: () => string[],
  clear: () => TLocalStorage,
};
export type TLocalStorageEvent = {
  key: string,
  value?: any,
};

export const localStorageProvider = (win: Window): TLocalStorage => {
  let locked = false;
  const $instance = createStore<TLocalStorageEvent>({key: ''});
  const {
    emit,
  } = createApi($instance, {
    emit: (_: TLocalStorageEvent, payload: TLocalStorageEvent) => payload,
  });
  const originLocalStorage = win.localStorage;
  function __set(key: string, value: any) {
    emit({key, value} as any);
    return $instance;
  }
  function getKeys() {
    const l = originLocalStorage.length;
    const keys = [];
    let i = 0;
    for (; i < l; i++) keys.push(originLocalStorage.key(i));
    return keys;
  }
  attachEvent(win as any, 'storage', (event: any) => {
    locked = true;
    emit({
      key: event.key,
      value: tryJsonParse(event.newValue),
    } as any);
    locked = false;
  });
  $instance.watch(({key, value}: TLocalStorageEvent) => {
    if (locked) return;
    value === null || value === undefined
      ? originLocalStorage.removeItem(key)
      : originLocalStorage.setItem(key, JSON.stringify(value));
  });
  ($instance as any).set = __set;
  ($instance as any).get = (key: string) => tryJsonParse(originLocalStorage.getItem(key));
  ($instance as any).remove = (key: string) => __set(key, null);
  ($instance as any).getKeys = getKeys;
  ($instance as any).clear = () => {
    forEach(getKeys(), (key) => {
      emit({key, value: null} as any);
    });
    return $instance;
  };
  return $instance as any;
};