import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getImageNaturalSizeByUrl } from '../../../utils/getImageNaturalSizeByUrl';
import { TSelectImageAreaProps, TSelectImageAreaValue } from './types';
import { AreaField, DEFAULT_SIZE } from './AreaField';
import { createTimeout } from '../../../utils/createTimeout';
import { Ripple } from '../Ripple';

export * from './types';

export const DEFAULT_PREVIEW_SIZE = 300;


export const SelectImageArea = React.memo((props: TSelectImageAreaProps) => {
  const {
    imageUrl,
    fields,
    value,
    onChange,
  } = props;
  
  const fieldOfFirst = fields[0];
  const idOfFirst = fieldOfFirst?.id || '';
  const [currentId, setCurrentId] = useState<string>(idOfFirst);
  const [size, setSize] = useState<[number, number]>(() => [0, 0]);
  const [width, height] = size;
  const isVertical = width < height;

  const qmaxW = isVertical ? (width || 1) / (height || 1) : 1;
  const qmaxH = isVertical ? 1 : (height || 1) / (width || 1);

  const fieldOfOrigin = fields.find((v) => v.id === currentId) || fieldOfFirst;
  const field = fieldOfOrigin || {};
  const _currentId = field.id || idOfFirst;
  const previewW = field.width || 1;
  const previewH = field.height || 1;

  useEffect(() => {
    return createTimeout(setCurrentId, 0, [idOfFirst]);
  }, [idOfFirst]);

  const styleOfContainer = useMemo(() => {
    return {
      height: DEFAULT_SIZE,
      width: DEFAULT_SIZE,
    };
  }, []);
  const styleOfImage = useMemo(() => {
    return isVertical ? {
      height: DEFAULT_SIZE,
    } : {
      width: DEFAULT_SIZE,
    };
  }, [isVertical]);

  const _value = useMemo(() => value || {}, [value]);
  const areas = useMemo(() => _value.areas || {}, [_value]);
  const item = useMemo(() => areas[_currentId] || {}, [areas, _currentId]);

  const x = item.x || 0;
  const y = item.y || 0;
  const scale = item.scale || 1;

  const styleOfPreviewImage = useMemo(() => {
    const isVerticalField = previewH > previewW; 
    const pqH = previewH / previewW;
    const pqW = 1 / pqH;

    const isLessFieldH = pqH < qmaxH;
    const isLessFieldW = pqW < qmaxW;
    
    const xMax = 1 - scale || 1;
    const yMax = 1 - (pqH / qmaxH) * scale || 1;

    const itemW = Math.abs(1 - (scale / pqH) * qmaxH) || 1;
    const itemH = Math.abs(1 - pqH * scale * qmaxW) || 1;

    let bgSize = 1;
    let bgx = 0;
    let bgy = 0;

    if (isVerticalField) {
      if (isVertical) {
        bgy = y / itemH;
        if (isLessFieldH) {
          bgSize = 1 / scale / qmaxH;
          bgx = x / xMax;
        } else {
          if (isLessFieldW) {
            bgSize = pqH * qmaxW / scale;
            bgx = x / itemW / qmaxW;
            bgy = y / xMax;
          } else {
            bgSize = 1 / scale;
            bgx = x / ((1 - scale) || 1);
          }
        }
      } else {
        bgx = x / itemW;
        bgy = y / xMax;
        
        if (isLessFieldH) {
          bgSize = scale;
        } else {
          bgSize = pqH / scale / qmaxH;
        }
      }
    } else {
      if (isVertical) {
        bgy = y / itemH;
        if (isLessFieldH) {
          bgx = x / xMax;
          bgSize = 1 / scale / qmaxH;
        } else {
          bgx = x / itemW;
          bgSize = 1 / scale / qmaxH;
        }
      } else {
        if (isLessFieldH) {
          bgx = x / xMax;
          bgy = y / yMax;
          bgSize = qmaxH / scale / qmaxH;
        } else {
          bgx = x / itemW;
          bgy = y / xMax;
          bgSize = pqH / scale / qmaxH;
        }
      }
    }

    /*
    console.log({
      isLessFieldH,
      isLessFieldW,
      isVertical,
      isVerticalField,
      pqH,
      pqW,
      qmaxW,
      qmaxH,
      previewW,
      previewH,
      xMax,
      yMax,
      itemW,
      itemH,
      scale,
      x,
      y,
      bgSize,
      bgx,
      bgy,
    });
    */

    return {
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: `${100 * bgx}% ${100 * bgy}%`,
      backgroundSize: `${100 * bgSize}%`,
      paddingTop: `${100 * pqH}%`,
    };
  }, [isVertical, imageUrl, x, y, scale, previewW, previewH, qmaxW, qmaxH]);


  useEffect(() => {
    if (!imageUrl) {
      return;
    }
    getImageNaturalSizeByUrl(imageUrl).then(
      setSize,
      (error: any) => {
        console.error('imageUrl', imageUrl, error);
      },
    );
  }, [imageUrl, setSize]);

  const handleChange = useCallback((areas: TSelectImageAreaValue) => {
    onChange({
      ..._value,
      areas,
      size,
    });
  }, [onChange, size, _value]);


  return (
    <div className={'dF fxdR aiFS jcS ' + (props.className || '')}>
      <div
        className='rlv dF fxdR aiC jcC bgF.05'
        style={styleOfContainer}
      >
        <div className='rlv'>
          <img
            className='dB usN eN'
            src={imageUrl}
            style={styleOfImage}
            alt=""
          />
          {fieldOfOrigin && width ? (
            <AreaField
              qmaxW={qmaxW}
              qmaxH={qmaxH}
              field={field}
              value={areas}
              onChange={handleChange}
            />
          ) : null}
        </div>
      </div>
      <div className='w400 bs b1 bcF.2 ml10'>
        {fields.map((field) => {
          const {
            id,
          } = field;
      
          return field ? (
            <Ripple
              key={id}
              className={'w hmin30 f12 lh cF dF fxdR aiC jcC pv5 ph10 bgF.1 bgF.2.active bs bcF.2 b0 bb1 bb0:last '
                + (id === _currentId ? 'active' : '')}
              onClick={() => {
                setCurrentId(id);
              }}
            >
              {field.width} x {field.height} | {field.name}
            </Ripple>
          ) : null;
        })}
      </div>
      <div className='w300 ml10'>
        <div
          className='w'
          style={styleOfPreviewImage}
        />
      </div>
    </div>
  );
});