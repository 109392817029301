import React from 'react';
import { throttle } from 'lodash';
import { attachEvent } from '../../../utils/attachEvent';
import { createTimeout } from '../../../utils/createTimeout';
import { TAreaFieldProps } from './types';
import { withState } from '../../../utils/withState';


export type TMove = {
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
};

export const DEFAULT_MIN_SIZE = 30;
export const DEFAULT_SIZE = 400;

function getViewPoint(e: any) {
  const touch = e.changedTouches?.[0] || e || {};
  return [
    touch.pageX || touch.clientX || 0,
    touch.pageY || touch.clientY || 0,
  ];
}

function getDefaultState() {
  return {
    move: {
      xStart: 0,
      xEnd: 0,
      yStart: 0,
      yEnd: 0,
    },
    size: {
      xStart: 0,
      xEnd: 0,
      yStart: 0,
      yEnd: 0,
    },
  };
}

export type TAreaFieldState = {
  move: TMove,
  size: TMove,
};

export const AreaField = withState<TAreaFieldProps, TAreaFieldState>((setState, self) => {
  let _xD = 0;
  let _yD = 0;
  let _newScale = 1;
  let _moveActive = false;
  let _sizeActive = false;

  self.state = getDefaultState();

  function setMove(move: Partial<TMove>) {
    setState({
      move: {
        ...self.state.move,
        ...move,
      },
    });
  }

  function setSize(size: Partial<TMove>) {
    setState({
      size: {
        ...self.state.size,
        ...size,
      },
    });
  }

  function handleMoveStart(e: any) {
    const [x, y] = getViewPoint(e);
    _moveActive = true;
    setMove({
      xStart: x,
      xEnd: x,
      yStart: y,
      yEnd: y,
    });
  }
  const handleMove = throttle((e: any) => {
    if (!_moveActive) {
      return;
    }
    const [x, y] = getViewPoint(e);
    setMove({
      xEnd: x,
      yEnd: y,
    });
  }, 50, {
    leading: false,
    trailing: true,
  });

  function handleSizeStart(e: any) {
    const [x, y] = getViewPoint(e);
    _sizeActive = true;
    setSize({
      xStart: x,
      xEnd: x,
      yStart: y,
      yEnd: y,
    });
  }

  const handleSize = throttle((e: any) => {
    if (!_sizeActive) {
      return;
    }
    const [x, y] = getViewPoint(e);
    setSize({
      xEnd: x,
      yEnd: y,
    });
  }, 50, {
    leading: false,
    trailing: true,
  });

  const {
    localEffect,
    localMemo,
  } = self;

  return (props, state) => {
    const {
      value,
      qmaxW,
      qmaxH,
      field,
    } = props;
    const {
      id,
      width,
      height,
    } = field;

    localEffect(() => {
      return attachEvent(window as any, 'mouseup', (e: any) => {
        const {
          props,
        } = self;
        if (!(_moveActive || _sizeActive)) {
          return;
        }
        _moveActive = _sizeActive = false;

        const {
          field,
        } = props;
        // console.log('mouseup');

        props.onChange({
          ...(props.value || {}),
          [field.id]: {
            ...field,
            x: _xD / (DEFAULT_SIZE * props.qmaxW),
            y: _yD / (DEFAULT_SIZE * props.qmaxH),
            scale: _newScale,
          },
        });
      });
    }, []);

    localEffect(() => {
      return attachEvent(window as any, 'mousemove', (e: any) => {
        handleMove(e);
        handleSize(e);
      });
    }, []);

    let qW = width / height;
    let qH = 1;

    if (width > height) {
      qW = 1;
      qH = height / width;
    }

    const item = localMemo(() => {
      const v = value?.[id] || {};

      return {
        x: (v.x || 0) * DEFAULT_SIZE * qmaxW,
        y: (v.y || 0) * DEFAULT_SIZE * qmaxH,
        scale: Math.max(Math.min(v.scale || 1, 1), 0.01),
      };
    }, [value, id, qmaxW, qmaxH]);

    const {
      x,
      y,
      scale,
    } = item;
    const {
      move,
      size,
    } = state;

    let scaleOfMain = 1;
    let sqwOfMain = qW;
    let sqhOfMain = qH;

    localEffect(() => {
      // console.log({ x, y, scale, id, width, height});
      _moveActive = _sizeActive = false;
      return createTimeout(setState, 0, [getDefaultState()]);
    }, [x, y, scale, id, width, height]);


    if (sqwOfMain > qmaxW) {
      sqwOfMain = qmaxW;
      scaleOfMain = sqwOfMain / qW;
      sqhOfMain = scaleOfMain * qH;
    }

    if (sqhOfMain > qmaxH) {
      sqhOfMain = qmaxH;
      scaleOfMain = sqhOfMain / qH;
      sqwOfMain = scaleOfMain * qW;
    }

    const sxD = size.xEnd - size.xStart;
    const syD = size.yEnd - size.yStart;

    const fw = sqwOfMain * DEFAULT_SIZE;
    const fh = sqhOfMain * DEFAULT_SIZE;

    const cw = scale * fw;
    const ch = scale * fh;

    const wrapperW = DEFAULT_SIZE * qmaxW;
    const wrapperH = DEFAULT_SIZE * qmaxH;

    const newScale = _newScale = _sizeActive
      ? Math.max(0.01, Math.min(
        Math.max(cw + Math.min(sxD, wrapperW - cw - x), DEFAULT_MIN_SIZE) / fw,
        Math.max(ch + Math.min(syD, wrapperH - ch - y), DEFAULT_MIN_SIZE) / fh,
        1,
      ))
      : scale;

    const itemSQW = sqwOfMain * newScale;
    const itemSQH = sqhOfMain * newScale;

    const innerW = DEFAULT_SIZE * itemSQW;
    const innerH = DEFAULT_SIZE * itemSQH;

    const xEnd = wrapperW - innerW;
    const yEnd = wrapperH - innerH;

    const xD = _xD = _moveActive ? Math.min(Math.max(x + move.xEnd - move.xStart, 0), xEnd) : x;
    const yD = _yD = _moveActive ? Math.min(Math.max(y + move.yEnd - move.yStart, 0), yEnd) : y;

    const style = localMemo(() => ({
      left: xD,
      top: yD,
      width: innerW,
      height: innerH,
    }), [xD, yD, innerW, innerH]);


    return (
      <div
        className={`dF fxdR aiC jcC tc f11 lh c0 abs bgF.6 bs bcF b1 p5 dn
          tp_opacity o70 o100(:h|.active) usN` + (_moveActive || _sizeActive ? ' active' : '')}
        style={style}
      >
        <div
          className='abs s crM'
          onMouseDown={handleMoveStart}
        />
        <div
          className='abs sb-1 sr-1 sq14 crM bg0.5 bs b1 bc0 z1'
          onMouseDown={handleSizeStart}
        />
      </div>
    );
  };

});
