import { $auth } from "../store/auth";
import * as api from "./common";


export type TAuthParams = {
  email: string,
  password: string,
};
export type TAuthResponse = api.TApiResponse<{
  token: string,
}>;

export const apiAuthLogin = (params: TAuthParams): Promise<TAuthResponse> => {
  return api.post({
    path: '/login_check',
    params,
  });
};

export const authLogin = async (params: TAuthParams) => {
  const response = await apiAuthLogin(params);
  const token = response?.data?.token || '';
  token && $auth.setState({
    email: params.email,
    token,
  });
  return response;
};