import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageCelebrationEmojisList } from './CelebrationEmojisList';
import { PageCelebrationEmojisEdit } from './CelebrationEmojisEdit';

export const PageCelebrationEmojis = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageCelebrationEmojisList}
      PageAdd={PageCelebrationEmojisEdit}
      PageEdit={PageCelebrationEmojisEdit}
    />
  );
});