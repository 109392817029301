
import {
  pushArray,
} from './pushArray';
import {
  regexpMapperProvider,
} from './regexpMapperProvider';

const REGEXP = /(\()|(\))?:([_A-Za-z0-9.]+)|(\))/g;


export const routeParseProviderBase = (route: string, keys: string[]) => {
  pushArray(keys, ['all']);
  const levels = [keys]; // eslint-disable-line
  let index = 0, depth = 0, lastDepth = 0; // eslint-disable-line
  // eslint-disable-next-line
  const prematcher = route.replace(REGEXP, (haystack, start, hasKey, key, end) => {
    if (start) {
      depth++;
      return '(';
    }
    depth--;
    let level = levels[depth] || (levels[depth] = []); // eslint-disable-line
    pushArray(level, [end ? index++ : key]);
    if (depth < lastDepth) {
      pushArray(level, levels[lastDepth]);
      levels[lastDepth] = [];
    }
    lastDepth = depth;
    return hasKey || end ? ')' : '([^/]*))';
  });
  return new RegExp(prematcher);
};

export const routeParseProvider = (route: string) => {
  const keys: any[] = [];
  return regexpMapperProvider(routeParseProviderBase(route, keys), keys);
};
