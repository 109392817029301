import React, { useCallback, useEffect, useMemo, useState } from 'react';
import trim from 'lodash/trim';
import { TFile, TMainBanner } from '../../../types';
import { apiImagesGetUrlById } from '../../../api/apiImages';
import { apiMainBanners } from '../../../api/apiMainBanners';
import { useItemFromStoreById } from '../../../utils/getItemFromStoreById';
import { backLocation } from '../../../utils/router';
import { $mainBanners, mainBannersAPI } from '../../../store/mainBanners';
import { TextFieldA } from '../../dumb/TextField/A';
import { ButtonB } from '../../dumb/Button/B';
import { TRouteProps } from '../../../utils/routerProvider';
import { WrapperWithLoading } from '../../dumb/WrapperWithLoading';
import { PhotoSelectA } from '../../smart/PhotoSelectA';
import { HeaderA } from '../../smart/Header/A';


export const PageMainBannersEdit = React.memo((props: TRouteProps) => {
  const params = props.params;
  const id: string = params.id || '';

  const [itemOfOrigin, setItemOfOrigin] = useItemFromStoreById($mainBanners, id, () => ({
    id,
    title: '',
    buttonText: '',
    image: '',
    url: '',
    position: 0,
  }), (v) => ({
    ...v,
    position: '' + v.position,
  }));

  const {
    title,
    image,
  } = itemOfOrigin;

  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [photos, _setPhotos] = useState<TFile[]>([]);

  useEffect(() => {
    id && apiMainBanners.getItem({
      id,
    }).then((response) => {
      const data = (response.data || {}) as TMainBanner;
      const image = data.image;
      const dataUrl = image ? apiImagesGetUrlById(image) : '';
      setItemOfOrigin(data);
      _setPhotos(image ? [
        {
          id: image,
          dataUrl,
          previewUrl: dataUrl,
        },
      ] : []);
    });
  }, [id]);

  const errorOfPhotos = photos.reduce(
    (a: boolean, v: TFile) => a || !!v.loading || !!v.error,
    false,
  );
  const errorOfPhotosExist = !image;

  const invalidTitle = useMemo(() => !trim(title), [title]);

  const disabled = invalidTitle
    || errorOfPhotos
    || errorOfPhotosExist;


  const handleChangeTitle = useCallback((e: any) => {
    setItemOfOrigin({
      title: e.target.value,
    });
  }, []);

  const handleChangeButtonText = useCallback((e: any) => {
    setItemOfOrigin({
      buttonText: e.target.value,
    });
  }, []);

  const handleChangeUrl = useCallback((e: any) => {
    setItemOfOrigin({
      url: e.target.value,
    });
  }, []);

  const handleChangePosition = useCallback((e: any) => {
    setItemOfOrigin((v) => {
      return {
        ...v,
        position: e.target.value.replace(/[^0-9]/gim, ''),
      };
    });
  }, []);


  const setPhotos = useCallback((items: TFile[]) => {
    _setPhotos(items);
    setItemOfOrigin({
      image: items[0]?.id || '',
    });
  }, []);

  const handleSubmit = useCallback(() => {
    setItemOfOrigin((data) => {
      if (disabled) {
        setClicked(true);
        return data;
      }

      setLoading(true);
      (async () => {
        const id = data.id;

        data = {
          ...data,
          title: trim(data.title),
          buttonText: trim(data.buttonText),
          image: trim(data.image),
          url: trim(data.url),
          position: parseInt(data.position as any),
        };
    
        // console.log('handleSubmit', data);

        if (id) {
          await apiMainBanners.change({
            id,
            params: data,
          });

          mainBannersAPI.edit({
            id,
            data,
          });

        } else {
          const response = await apiMainBanners.create({
            params: data,
          });

          data = response.data || data;

          mainBannersAPI.add(data);
        }

        backLocation();

        setLoading(false);
      })();
      
      return data;
    });
    
  }, [disabled]);

  
  return (
    <div className='sq'>
      <HeaderA id={id}>
        {id ? 'Редактирование баннера:' : 'Новый баннер:'} {title}
      </HeaderA>
      <WrapperWithLoading
        className='bs bs b bt1 bcF.1 p15'
        loading={loading}
      >
        <PhotoSelectA
          className='mt10'
          error={clicked && errorOfPhotosExist}
          limit={1}
          value={photos}
          onChange={setPhotos}
        />
        <TextFieldA
          className='mt15'
          label="Название"
          value={title}
          onChange={handleChangeTitle}
          error={invalidTitle && clicked}
        />
        <TextFieldA
          className='mt15'
          label="Текст кнопки"
          value={itemOfOrigin.buttonText}
          onChange={handleChangeButtonText}
        />
        <TextFieldA
          className='mt10'
          label="URL"
          value={itemOfOrigin.url}
          onChange={handleChangeUrl}
        />
        <TextFieldA
          className='mt15'
          label="Position"
          value={itemOfOrigin.position}
          onChange={handleChangePosition}
        />
        <ButtonB
          className='w200 mt15'
          onClick={handleSubmit}
          disabled={disabled && clicked}
        >
          Сохранить
        </ButtonB>
      </WrapperWithLoading>
    </div>
  );
});