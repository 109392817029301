import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageCelebrationsList } from './CelebrationsList';
import { PageCelebrationsEdit } from './CelebrationsEdit';

export const PageCelebrations = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageCelebrationsList}
      PageAdd={PageCelebrationsEdit}
      PageEdit={PageCelebrationsEdit}
    />
  );
});