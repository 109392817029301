import React, { useCallback, useMemo, useState } from 'react';
import trim from 'lodash/trim';
import { TextFieldA } from '../../dumb/TextField/A';
import { ButtonB } from '../../dumb/Button/B';
import { authLogin } from '../../../api/apiAuth';
import { pushCustomModalError } from '../../../services/customModals/modals/pushCustomModalError';
import { WrapperWithLoading } from '../../dumb/WrapperWithLoading';


/*
(async() => {
  const response: any = await apiAuthLogin({
    email: 'test@test.ru',
    password: 'TestPassword',
  });
  console.log('apiAuthLogin:end', response);
})();
*/

export const PageLogin = React.memo((props) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(false);

  const invalidLogin = useMemo(() => !trim(login), [login]);
  const invalidPassword = useMemo(() => !trim(password), [password]);
  const disabled = invalidLogin || invalidPassword;

  // console.log('clicked && disabled', clicked && disabled);


  const handleChangeLogin = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setLogin(e.target.value);
  }, [setLogin]);

  const handleChangePassword = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(e.target.value);
  }, [setPassword]);
  
  const handleSubmit = useCallback((e: any) => {
    e.preventDefault?.();
    e.stopPropagation?.();

    setLogin((email) => {
      setPassword((password) => {
        const emailInvalid = !trim(email);
        const passwordInvalid = !trim(password);
        const disabled = emailInvalid || passwordInvalid;

        if (disabled) {
          setClicked(true);
        } else {
          setLoading(true);
          authLogin({
            email,
            password,
          })
          .then((response) => {
            response?.error && pushCustomModalError({
              title: 'Неправильный логин или пароль',
            });
            setLoading(false);
          });
        }

        return password;
      });
      return email;
    });
    return false;
  }, [setLogin, setPassword, setClicked, setLoading]);

  return (
    <div className='sq dF fxdR aiC jcC'>
      <div className='w320 dB bgF.1 p15 r5'>
        <div className='tc f18'>
          Авторизация
        </div>
        <WrapperWithLoading loading={loading}>
          <form onSubmit={handleSubmit}>
            <TextFieldA
              className='mt15'
              name='login'
              label='Логин'
              value={login}
              onChange={handleChangeLogin}
              error={clicked && invalidLogin}
            />
            <TextFieldA
              className='mt10'
              name='password'
              label='Пароль'
              value={password}
              onChange={handleChangePassword}
              error={clicked && invalidPassword}
            />
            <div className='mt15'>
              <ButtonB
                component='button'
                type="submit"
                disabled={(clicked && disabled) || loading}
              >
                Войти
              </ButtonB>
            </div>
          </form>
        </WrapperWithLoading>
      </div>
    </div>
  );
});