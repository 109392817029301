import React from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  routerProvider,
} from './routerProvider';

const router = routerProvider({
  window,
  Component: React.Component,
  createElement: React.createElement,
  forwardRef: React.forwardRef,
});

export const Link = router.HashLink;
export const NavLink = router.HashNavLink;
export const $location = router.$hashLocation;
export const $path = router.$hashPath;
export const $query = router.$hashQuery;
export const paramStorage = router.hashParamStorage;
export const routerByLocationProvider = router.routerByHashLocationProvider;
export const pushLocation = throttle(router.pushHashLocation, 50, {
  leading: false,
});
export const replaceLocation = throttle(router.replaceHashLocation, 50, {
  leading: false,
});
export const backLocation = router.backLocation;
export const backLocationDefer = debounce(backLocation, 50);
export const backLocationDebounced = debounce(backLocation, 250);