import { localStore } from '../utils/stores';


export type TAuthState = {
  email: string,
  token: string,
};

export const $auth = localStore<TAuthState | null>('__auth', null);
export const handleLogOut = () => {
  $auth.setState(null);
};