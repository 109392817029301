import React, { useEffect } from "react";
import { SelectProps } from "@mui/material";
import { useUnit } from "effector-react";
import { $tags, tagsAPI } from "../../store/tags";
import { apiTags } from "../../api/apiTags";
import { SelectBase } from "../dumb/Select/Base";


export const SelectSmartTags = React.memo((props: SelectProps) => {
  const items = useUnit($tags);

  useEffect(() => {
    apiTags.getList().then((response) => {
      tagsAPI.set((response?.data || []).map((v) => ({
        ...v,
        id: v.name,
      })));
    });
  }, []);

  return (
    <SelectBase
      {...props}
      items={items}
      multiple
    />
  );
});