import React, { useEffect } from "react";
import { SelectProps } from "@mui/material";
import { useUnit } from "effector-react";
import { $celebrations, celebrationsAPI } from "../../store/celebrations";
import { apiCelebrations } from "../../api/apiCelebrations";
import { SelectBase } from "../dumb/Select/Base";


export const SelectSmartCelebrationId = React.memo((props: SelectProps) => {
  const items = useUnit($celebrations);

  useEffect(() => {
    apiCelebrations.getList().then((response) => {
      celebrationsAPI.set(response?.data || []);
    });
  }, []);

  return (
    <SelectBase
      {...props}
      items={items}
    />
  );
});