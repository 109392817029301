import React from 'react';
import { TRouteProps } from '../../../utils/routerProvider';
import { PageListBaseA } from '../../smart/PageListBaseA';
import { PageMainBannersList } from './MainBannersList';
import { PageMainBannersEdit } from './MainBannersEdit';


export const PageMainBanners = React.memo((props: TRouteProps) => {
  return (
    <PageListBaseA
      {...props}
      PageList={PageMainBannersList}
      PageAdd={PageMainBannersEdit}
      PageEdit={PageMainBannersEdit}
    />
  );
});