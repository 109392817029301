import isArray from 'lodash/isArray';


export const withoutEmpty = (data: any, depth?: number) => {
  return withoutEmptyBase(data, depth || 0);
};

export const withoutEmptyBase = (src: any, depth: number): any => {
  if (depth < 0) return src;
  depth--;
  const type = typeof(src);
  let dst: any = null, v: any, k: string;
  if (src === null || src === undefined || (type === 'string' && !src)) {
    return null;
  }
  if (type != 'object') return src; // eslint-disable-line
  if (isArray(src)) return src.length ? src : null;
  for (k in src) {
    ((v = withoutEmptyBase(src[k], depth)) === null) || ((dst || (dst = {}))[k] = v);
  }
  return dst;
};

